import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './LoginComponent/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <AuthProvider >
  <React.StrictMode>
  <App />
  </React.StrictMode>
  </AuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Form, Spinner, Alert, Modal } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://94.130.56.59:5001/api/employees');
        setEmployees(response.data);
        setLoading(false);
      } catch (error) {
        setAlert({ show: true, message: 'Failed to fetch employees. Please try again later.', type: 'danger' });
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleDelete = async () => {
    try {
      await axios.delete(`https://94.130.56.59:5001/api/employees/${deleteId}`);
      setEmployees(employees.filter(emp => emp._id !== deleteId));
      setAlert({ show: true, message: 'Employee deleted successfully!', type: 'success' });
    } catch (error) {
      setAlert({ show: true, message: 'Failed to delete employee. Please try again later.', type: 'danger' });
    }
    setShowDeleteModal(false);
  };

  const filteredEmployees = employees.filter(emp =>
    emp.name.toLowerCase().includes(search.toLowerCase()) ||
    emp.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Container className="my-5">
      <h1 className="mb-4 text-center">Employee Directory</h1>

      <Form.Group className="mb-4">
        <Form.Control
          type="text"
          placeholder="Search Employee"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="rounded-pill"
        />
      </Form.Group>

      <Button
        variant="primary"
        as={Link}
        to="/employees/add"
        className="mb-4 d-block mx-auto"
        style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
      >
        Add New Employee
      </Button>

      {loading ? (
        <div className="d-flex justify-content-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Row>
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map(employee => (
              <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex" key={employee._id}>
                <Card className="w-100 h-100 d-flex flex-column">
                  <Card.Img
                    variant="top"
                    src={employee.picture ? `${window.location.origin}/uploads/${employee.picture}` : '/default-avatar.png'}
                    alt={employee.name}
                    style={{ objectFit: 'cover', height: '200px' }}
                  />
                  <Card.Body className="d-flex flex-column">
                    <Card.Title className="text-center">{employee.name}</Card.Title>
                    <Card.Text className="text-center flex-grow-1">
                      <strong>Role:</strong> {employee.role}<br />
                      <strong>Email:</strong> {employee.email}
                    </Card.Text>
                    <div className="d-flex justify-content-around mt-3">
                      <Button
                        variant="warning"
                        as={Link}
                        to={`/employees/edit/${employee._id}`}
                        style={{ width: '45%' }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => { setShowDeleteModal(true); setDeleteId(employee._id); }}
                        style={{ width: '45%' }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col className="text-center">
              <p>No employees found</p>
            </Col>
          )}
        </Row>
      )}

      <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
        {alert.message}
      </Alert>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this employee?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EmployeeList;

















// // src/components/EmployeeList.jsx
// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Card, Button, Container, Row, Col, Form, Spinner, Alert, Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// // Sample demo data
// const demoEmployees = [
//   {
//     _id: '1',
//     name: 'John Doe',
//     role: 'Software Engineer',
//     email: 'john.doe@example.com',
//     picture: 'john-doe.jpg',
//   },
//   {
//     _id: '2',
//     name: 'Jane Smith',
//     role: 'Project Manager',
//     email: 'jane.smith@example.com',
//     picture: 'jane-smith.jpg',
//   },
//   {
//     _id: '3',
//     name: 'Alice Johnson',
//     role: 'UI/UX Designer',
//     email: 'alice.johnson@example.com',
//     picture: 'alice-johnson.jpg',
//   },
//   {
//     _id: '4',
//     name: 'Bob Brown',
//     role: 'QA Engineer',
//     email: 'bob.brown@example.com',
//     picture: 'bob-brown.jpg',
//   },
// ];

// const EmployeeList = () => {
//   const [employees, setEmployees] = useState([]);
//   const [search, setSearch] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [alert, setAlert] = useState({ show: false, message: '', type: '' });
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);

//   useEffect(() => {
//     // Simulate API call with demo data
//     setTimeout(() => {
//       setEmployees(demoEmployees);
//       setLoading(false);
//     }, 1000); // Simulate a delay of 1 second
//   }, []);

//   const handleDelete = () => {
//     setEmployees(employees.filter(emp => emp._id !== deleteId));
//     setAlert({ show: true, message: 'Employee deleted successfully!', type: 'success' });
//     setShowDeleteModal(false);
//   };

//   const filteredEmployees = employees.filter(emp =>
//     emp.name.toLowerCase().includes(search.toLowerCase()) ||
//     emp.email.toLowerCase().includes(search.toLowerCase())
//   );

//   return (
//     <Container className="my-5">
//       <h1 className="mb-4 text-center">Employee Directory</h1>

//       <Form.Group className="mb-4">
//         <Form.Control
//           type="text"
//           placeholder="Search Employee"
//           value={search}
//           onChange={(e) => setSearch(e.target.value)}
//           className="rounded-pill"
//         />
//       </Form.Group>

//       <Button
//         variant="primary"
//         as={Link}
//         to="/employees/add"
//         className="mb-4 d-block mx-auto"
//         style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
//       >
//         Add New Employee
//       </Button>

//       {loading ? (
//         <div className="d-flex justify-content-center my-4">
//           <Spinner animation="border" variant="primary" />
//         </div>
//       ) : (
//         <Row>
//           {filteredEmployees.length > 0 ? (
//             filteredEmployees.map(employee => (
//               <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex" key={employee._id}>
//                 <Card className="w-100 h-100 d-flex flex-column">
//                   <Card.Img
//                     variant="top"
//                     src={employee.picture ? `${window.location.origin}/uploads/${employee.picture}` : '/default-avatar.png'}
//                     alt={employee.name}
//                     style={{ objectFit: 'cover', height: '200px' }}
//                   />
//                   <Card.Body className="d-flex flex-column">
//                     <Card.Title className="text-center">{employee.name}</Card.Title>
//                     <Card.Text className="text-center flex-grow-1">
//                       <strong>Role:</strong> {employee.role}<br />
//                       <strong>Email:</strong> {employee.email}
//                     </Card.Text>
//                     <div className="d-flex justify-content-around mt-3">
//                       <Button
//                         variant="warning"
//                         as={Link}
//                         to={`/employees/edit/${employee._id}`}
//                         style={{ width: '45%' }}
//                       >
//                         Edit
//                       </Button>
//                       <Button
//                         variant="danger"
//                         onClick={() => { setShowDeleteModal(true); setDeleteId(employee._id); }}
//                         style={{ width: '45%' }}
//                       >
//                         Delete
//                       </Button>
//                     </div>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))
//           ) : (
//             <Col className="text-center">
//               <p>No employees found</p>
//             </Col>
//           )}
//         </Row>
//       )}

//       <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
//         {alert.message}
//       </Alert>

//       {/* Delete Confirmation Modal */}
//       <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Delete</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Are you sure you want to delete this employee?</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
//           <Button variant="danger" onClick={handleDelete}>Delete</Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default EmployeeList;

import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card, Alert } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

const CreateBlogPostPage = () => {
  const [blog, setBlog] = useState({
    title: '',
    author: '',
    category: '',
    content: '',
    image: null,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  const handleContentChange = (content) => {
    setBlog({ ...blog, content });
  };

  const handleImageChange = (e) => {
    setBlog({ ...blog, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', blog.title);
    formData.append('author', blog.author);
    formData.append('category', blog.category);
    formData.append('content', blog.content);
    if (blog.image) {
      formData.append('image', blog.image);
    }

    try {
      await axios.post('https://94.130.56.59:5001/blog-posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess('Blog post created successfully!');
      setError(null); // Clear any previous errors
      setBlog({
        title: '',
        author: '',
        category: '',
        content: '',
        image: null,
      });
    } catch (err) {
      setError('Failed to create blog post. Please try again.');
      setSuccess(null); // Clear any previous success messages
    }
  };

  return (
    <Container fluid className="mt-4" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <Card className="p-4 shadow-sm">
        <h2 className="text-center text-primary mb-4">Create Blog Post</h2>
        {error && (
          <Row className="mb-3">
            <Col>
              <Alert variant="danger">
                {error}
              </Alert>
            </Col>
          </Row>
        )}
        {success && (
          <Row className="mb-3">
            <Col>
              <Alert variant="success">
                {success}
              </Alert>
            </Col>
          </Row>
        )}
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formBlogTitle">
                <Form.Label className="text-info">Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={blog.title}
                  onChange={handleChange}
                  placeholder="Enter blog title"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formBlogAuthor">
                <Form.Label className="text-info">Author</Form.Label>
                <Form.Control
                  type="text"
                  name="author"
                  value={blog.author}
                  onChange={handleChange}
                  placeholder="Enter author name"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formBlogCategory">
                <Form.Label className="text-info">Category</Form.Label>
                <Form.Control
                  type="text"
                  name="category"
                  value={blog.category}
                  onChange={handleChange}
                  placeholder="Enter blog category"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formBlogImage">
                <Form.Label className="text-info">Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleImageChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="formBlogContent">
                <Form.Label className="text-info">Content</Form.Label>
                <ReactQuill
                  value={blog.content}
                  onChange={handleContentChange}
                  theme="snow"
                  placeholder="Write your blog content here..."
                  style={{ height: '200px' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-6">
            <Col>
              <Button  ype="submit" variant="primary" className="w-100">
                Save Blog Post
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Container>
  );
};

export default CreateBlogPostPage;








// import React, { useState } from 'react';
// import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// const CreateBlogPostPage = () => {
//   const [blog, setBlog] = useState({
//     title: '',
//     author: '',
//     category: '',
//     content: '',
//     image: null,
//   });

//   const handleChange = (e) => {
//     setBlog({ ...blog, [e.target.name]: e.target.value });
//   };

//   const handleContentChange = (content) => {
//     setBlog({ ...blog, content });
//   };

//   const handleImageChange = (e) => {
//     setBlog({ ...blog, image: e.target.files[0] });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log(blog); // Handle form submission (e.g., API call)
//     setBlog({
//       title: '',
//       author: '',
//       category: '',
//       content: '',
//       image: null,
//     });
//   };

//   return (
//     <Container fluid className="mt-4" style={{ fontFamily: 'Roboto, sans-serif' }}>
//       <Card className="p-4 shadow-sm">
//         <h2 className="text-center text-primary mb-4">Create Blog Post</h2>
//         <Form onSubmit={handleSubmit}>
//           <Row className="mb-3">
//             <Col md={6}>
//               <Form.Group controlId="formBlogTitle">
//                 <Form.Label className="text-info">Title</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="title"
//                   value={blog.title}
//                   onChange={handleChange}
//                   placeholder="Enter blog title"
//                   required
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group controlId="formBlogAuthor">
//                 <Form.Label className="text-info">Author</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="author"
//                   value={blog.author}
//                   onChange={handleChange}
//                   placeholder="Enter author name"
//                   required
//                 />
//               </Form.Group>
//             </Col>
//           </Row>

//           <Row className="mb-3">
//             <Col md={6}>
//               <Form.Group controlId="formBlogCategory">
//                 <Form.Label className="text-info">Category</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="category"
//                   value={blog.category}
//                   onChange={handleChange}
//                   placeholder="Enter blog category"
//                   required
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group controlId="formBlogImage">
//                 <Form.Label className="text-info">Upload Image</Form.Label>
//                 <Form.Control
//                   type="file"
//                   onChange={handleImageChange}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>

//           <Row className="mb-3">
//             <Col md={12}>
//               <Form.Group controlId="formBlogContent">
//                 <Form.Label className="text-info">Content</Form.Label>
//                 <ReactQuill
//                   value={blog.content}
//                   onChange={handleContentChange}
//                   theme="snow"
//                   placeholder="Write your blog content here..."
//                   style={{ height: '200px' }}
//                 />
//               </Form.Group>
//             </Col>
//           </Row>

//           <Row className="mt-4">
//             <Col>
//               <Button type="submit" variant="primary" className="w-100">
//                 Save Blog Post
//               </Button>
//             </Col>
//           </Row>
//         </Form>
//       </Card>
//     </Container>
//   );
// };

// export default CreateBlogPostPage;

import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import axios from 'axios';
import { Container, Row, Col, Table, Button, InputGroup, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FaSearch, FaTimes } from 'react-icons/fa';

// Global Filter Component
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <InputGroup className="mb-3">
    <Form.Control
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder="Search..."
      aria-label="Search"
      aria-describedby="search-icon"
    />
    <InputGroup.Text id="search-icon">
      <FaSearch />
      {globalFilter && (
        <OverlayTrigger
          placement="end"
          overlay={<Tooltip id="clear-tooltip">Clear search</Tooltip>}
        >
          <Button variant="link" onClick={() => setGlobalFilter('')}>
            <FaTimes />
          </Button>
        </OverlayTrigger>
      )}
    </InputGroup.Text>
  </InputGroup>
);

const StudentFormTable = () => {
  const [data, setData] = useState([]);

  // Fetch data from the backend API
  useEffect(() => {
    axios.get('https://94.130.56.59/api/student-data')
      .then(response => {
        setData(response.data); // Set the data from your API into state
      })
      .catch(error => {
        console.error('Error fetching student data:', error);
      });
  }, []);

  // Memoizing columns to avoid re-renders
  const columns = useMemo(() => [
    { Header: 'Student Name', accessor: 'name' },
    { Header: 'Registration No', accessor: 'registrationNo' },
    { Header: 'Date of Admission', accessor: 'dateOfAdmission' },
    { Header: 'Class', accessor: 'class' },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <Button
          variant="primary"
          onClick={() => viewDetails(row.original)}
        >
          View Details
        </Button>
      )
    }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy
  );

  const viewDetails = (student) => {
    // Implement the logic to view student details
    console.log('View details for student:', student);
    // For example, navigate to a details page or open a modal
  };

  return (
    <Container fluid className="mt-4">
      <Row className="mb-3">
        <Col>
          <h2 className="text-center text-primary">Student List</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        </Col>
        <Col md={6} className="text-end">
          <CSVLink
            data={data}
            headers={columns.map(col => ({ label: col.Header, key: col.accessor }))}
            filename="student-data.csv"
            className="btn btn-secondary ms-2"
          >
            Export to CSV
          </CSVLink>
        </Col>
      </Row>
      <Table {...getTableProps()} striped bordered hover responsive>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default StudentFormTable;

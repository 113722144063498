// src/components/AttendanceManagement/StudentAttendanceReport.js
import React, { useState } from 'react';
import { Container, Typography, Paper, Grid, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';

const StudentAttendanceReport = () => {
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    studentName: '',
    status: ''
  });

  const [rows, setRows] = useState([]); // This would be your data source

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    // Implement search logic here based on filters
    console.log(filters);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Student Name', width: 200 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    // Add more columns as needed
  ];

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ color: deepPurple[700] }}>
          Student Attendance Report
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Student Name"
                name="studentName"
                value={filters.studentName}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Start Date"
                name="startDate"
                type="date"
                value={filters.startDate}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="End Date"
                name="endDate"
                type="date"
                value={filters.endDate}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  name="status"
                  value={filters.status}
                  onChange={handleChange}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Present">Present</MenuItem>
                  <MenuItem value="Absent">Absent</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                sx={{ width: '100%' }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} pageSize={10} />
        </div>
      </Paper>
    </Container>
  );
};

export default StudentAttendanceReport;

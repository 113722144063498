import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

const RoleManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [branches, setBranches] = useState([]);
  const [roles, setRoles] = useState(["Admin", "Manager", "Staff"]);

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  // Fetch employee and branch data from backend API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeResponse = await fetch("https://94.130.56.59:5001/api/employees");
        const employeesData = await employeeResponse.json();
        setEmployees(employeesData);

        const branchResponse = await fetch("https://94.130.56.59:5001/api/branches");
        const branchesData = await branchResponse.json();
        setBranches(branchesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSave = () => {
    if (!selectedEmployee || !selectedBranch || !selectedRole || !username || !password || !retypePassword) {
      alert("Please fill all fields");
      return;
    }

    if (password !== retypePassword) {
      alert("Passwords do not match");
      return;
    }

    const updatedData = {
      employeeId: selectedEmployee,
      branchId: selectedBranch,
      role: selectedRole,
      username,
      password,
    };

    fetch(`/api/employees/${selectedEmployee}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Role, branch, and login credentials updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  return (
    <Container className="mt-5">
      <h3 className="text-center mb-4" style={{ color: "#343a40", fontWeight: "bold", fontFamily: "'Poppins', sans-serif" }}>
        Manage Employee Roles & Branches
      </h3>
      <Row className="justify-content-center">
        <Col md={6} className="mx-auto">
          <Form className="p-4" style={{ border: "1px solid #ccc" }}> {/* Lighter border added here */}
            {/* Employee Dropdown */}
            <Form.Group controlId="employeeSelect" className="mb-3">
              <Form.Label style={{ fontFamily: "'Poppins', sans-serif" }}>Employee</Form.Label>
              <Form.Control
                as="select"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                <option value="">Select Employee</option>
                {employees.map((employee) => (
                  <option key={employee._id} value={employee._id}>
                    {employee.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Branch Dropdown */}
            <Form.Group controlId="branchSelect" className="mb-3">
              <Form.Label style={{ fontFamily: "'Poppins', sans-serif" }}>Branch</Form.Label>
              <Form.Control
                as="select"
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
              >
                <option value="">Select Branch</option>
                {branches.map((branch) => (
                  <option key={branch._id} value={branch._id}>
                    {branch.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Role Dropdown */}
            <Form.Group controlId="roleSelect" className="mb-3">
              <Form.Label style={{ fontFamily: "'Poppins', sans-serif" }}>Role</Form.Label>
              <Form.Control
                as="select"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Select Role</option>
                {roles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Username Field */}
            <Form.Group controlId="username" className="mb-3">
              <Form.Label style={{ fontFamily: "'Poppins', sans-serif" }}>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter username"
              />
            </Form.Group>

            {/* Password Field */}
            <Form.Group controlId="password" className="mb-3">
              <Form.Label style={{ fontFamily: "'Poppins', sans-serif" }}>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
            </Form.Group>

            {/* Retype Password Field */}
            <Form.Group controlId="retypePassword" className="mb-3">
              <Form.Label style={{ fontFamily: "'Poppins', sans-serif" }}>Retype Password</Form.Label>
              <Form.Control
                type="password"
                value={retypePassword}
                onChange={(e) => setRetypePassword(e.target.value)}
                placeholder="Retype password"
              />
            </Form.Group>

            {/* Save Button */}
            <Button
              variant="primary"
              className="w-100 mt-3"
              onClick={handleSave}
              style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
            >
              Save Changes
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default RoleManagement;

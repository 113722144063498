import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, ListGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import axios from 'axios';

const BranchDashboard = () => {
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({
    totalStudents: 0,
    totalEmployees: 0,
    revenue: 0,
    admissionsToday: 0,
    feeEstimation: 0,
    collections: 0,
    remainings: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('https://94.130.56.59:5001/api/branch-dashboard');
        const { chartData, statsData } = response.data;
        setData(chartData);
        setStats(statsData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch dashboard data.');
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <Container fluid className="p-4">
      {/* Branch Header */}
      <Row className="mb-4">
        <Col>
          <h1 className="text-center text-primary">Branch Dashboard</h1>
          <DropdownButton id="branch-dropdown" title="Select Branch" variant="primary" className="mb-4">
            <Dropdown.Item href="#/action-1">Branch 1</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Branch 2</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Branch 3</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>

      {/* Statistics Cards */}
      <Row className="mb-4">
        <Col md={4}>
          <Card className="border-success shadow-sm">
            <Card.Header className="bg-success text-white">Total Students</Card.Header>
            <Card.Body className="text-center">
              <h2 className="text-success">{loading ? 'Loading...' : stats.totalStudents}</h2>
              <p>This Month: {loading ? 'Loading...' : stats.totalStudents}</p>
              <Button variant="outline-success">View Details</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="border-danger shadow-sm">
            <Card.Header className="bg-danger text-white">Total Employees</Card.Header>
            <Card.Body className="text-center">
              <h2 className="text-danger">{loading ? 'Loading...' : stats.totalEmployees}</h2>
              <p>This Month: {loading ? 'Loading...' : stats.totalEmployees}</p>
              <Button variant="outline-danger">View Details</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="border-warning shadow-sm">
            <Card.Header className="bg-warning text-dark">Revenue</Card.Header>
            <Card.Body className="text-center">
              <h2 className="text-warning">${loading ? 'Loading...' : stats.revenue}</h2>
              <p>This Month: ${loading ? 'Loading...' : stats.revenue}</p>
              <Button variant="outline-warning">View Details</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Branch Performance Chart */}
      <Row className="mb-4">
        <Col>
          <Card className="bg-light shadow-sm">
            <Card.Header>Branch Performance Overview</Card.Header>
            <Card.Body>
              <BarChart width={800} height={400} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="students" fill="#1976d2" />
                <Bar dataKey="employees" fill="#f57c00" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      {/* Detailed Statistics */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header>Today's Admissions</Card.Header>
            <Card.Body>
              <h3 className="text-center text-primary">{loading ? 'Loading...' : stats.admissionsToday}</h3>
              <p className="text-center">No new admissions today.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header>Estimated Fee Collection</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>Estimation: ${loading ? 'Loading...' : stats.feeEstimation}</ListGroup.Item>
                <ListGroup.Item>Collections: ${loading ? 'Loading...' : stats.collections}</ListGroup.Item>
                <ListGroup.Item>Remainings: ${loading ? 'Loading...' : stats.remainings}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BranchDashboard;








// // src/components/BranchDashboard.jsx
// import React from 'react';
// import { Container, Row, Col, Card, Button, ListGroup, Dropdown, DropdownButton } from 'react-bootstrap';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

// // Sample data for charts
// const data = [
//   { name: 'Jan', students: 400, employees: 100 },
//   { name: 'Feb', students: 300, employees: 90 },
//   { name: 'Mar', students: 500, employees: 120 },
//   { name: 'Apr', students: 600, employees: 130 },
//   { name: 'May', students: 700, employees: 140 },
//   { name: 'Jun', students: 800, employees: 150 },
// ];

// const BranchDashboard = () => {
//   return (
//     <Container fluid className="p-4">
//       {/* Branch Header */}
//       <Row className="mb-4">
//         <Col>
//           <h1 className="text-center text-primary">Branch Dashboard</h1>
//           <DropdownButton id="branch-dropdown" title="Select Branch" variant="primary" className="mb-4">
//             <Dropdown.Item href="#/action-1">Branch 1</Dropdown.Item>
//             <Dropdown.Item href="#/action-2">Branch 2</Dropdown.Item>
//             <Dropdown.Item href="#/action-3">Branch 3</Dropdown.Item>
//           </DropdownButton>
//         </Col>
//       </Row>

//       {/* Statistics Cards */}
//       <Row className="mb-4">
//         <Col md={4}>
//           <Card className="border-success shadow-sm">
//             <Card.Header className="bg-success text-white">Total Students</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-success">0</h2>
//               <p>This Month: 0</p>
//               <Button variant="outline-success">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={4}>
//           <Card className="border-danger shadow-sm">
//             <Card.Header className="bg-danger text-white">Total Employees</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-danger">0</h2>
//               <p>This Month: 0</p>
//               <Button variant="outline-danger">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={4}>
//           <Card className="border-warning shadow-sm">
//             <Card.Header className="bg-warning text-dark">Revenue</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-warning">$0</h2>
//               <p>This Month: $0</p>
//               <Button variant="outline-warning">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Branch Performance Chart */}
//       <Row className="mb-4">
//         <Col>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Branch Performance Overview</Card.Header>
//             <Card.Body>
//               <BarChart width={800} height={400} data={data}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Bar dataKey="students" fill="#1976d2" />
//                 <Bar dataKey="employees" fill="#f57c00" />
//               </BarChart>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
      
//       {/* Detailed Statistics */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Today's Admissions</Card.Header>
//             <Card.Body>
//               <h3 className="text-center text-primary">0</h3>
//               <p className="text-center">No new admissions today.</p>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Estimated Fee Collection</Card.Header>
//             <Card.Body>
//               <ListGroup variant="flush">
//                 <ListGroup.Item>Estimation: $0</ListGroup.Item>
//                 <ListGroup.Item>Collections: $0</ListGroup.Item>
//                 <ListGroup.Item>Remainings: $0</ListGroup.Item>
//               </ListGroup>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default BranchDashboard;

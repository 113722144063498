// src/components/AttendanceManagement/AttendanceReport.jsx
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Card, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

// Register necessary components
ChartJS.register(CategoryScale, LinearScale, LineElement, BarElement, Title, Tooltip, Legend, PointElement);

const sampleData = [
  { id: 1, studentName: 'John Doe', className: 'Class A', date: '2024-09-01', status: 'Present' },
  { id: 2, studentName: 'Jane Smith', className: 'Class B', date: '2024-09-01', status: 'Absent' },
  // Add more sample data here
];

const AttendanceReport = () => {
  const [filter, setFilter] = useState({ studentName: '', className: '', date: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = sampleData.filter(item => {
    return (
      (filter.studentName ? item.studentName.toLowerCase().includes(filter.studentName.toLowerCase()) : true) &&
      (filter.className ? item.className.toLowerCase().includes(filter.className.toLowerCase()) : true) &&
      (filter.date ? item.date.includes(filter.date) : true)
    );
  });

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Attendance Over Time',
        data: [65, 59, 80, 81, 56, 55, 40],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
      },
    },
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <Container className="my-5">
      <Card>
        <Card.Header as="h4" className="bg-primary text-white">
          Attendance Report
        </Card.Header>
        <Card.Body>
          <Form>
            <Row className="mb-4">
              <Col xs={12} md={4}>
                <Form.Group controlId="studentName">
                  <Form.Label>Student Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="studentName"
                    value={filter.studentName}
                    onChange={handleChangeFilter}
                    placeholder="Enter student name"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="className">
                  <Form.Label>Class</Form.Label>
                  <Form.Control
                    type="text"
                    name="className"
                    value={filter.className}
                    onChange={handleChangeFilter}
                    placeholder="Enter class name"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={filter.date}
                    onChange={handleChangeFilter}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" onClick={() => setPage(0)}>Apply Filters</Button>
            <Button variant="secondary" className="ms-2" onClick={() => setFilter({ studentName: '', className: '', date: '' })}>Clear Filters</Button>
          </Form>
          <Card className="mt-4">
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Class</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <tr key={row.id}>
                      <td>{row.studentName}</td>
                      <td>{row.className}</td>
                      <td>{row.date}</td>
                      <td>{row.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination className="mt-3">
                <Pagination.Prev onClick={() => handleChangePage(null, Math.max(page - 1, 0))} disabled={page === 0} />
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item key={index} active={index === page} onClick={() => handleChangePage(null, index)}>
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handleChangePage(null, Math.min(page + 1, totalPages - 1))} disabled={page >= totalPages - 1} />
              </Pagination>
            </Card.Body>
          </Card>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Card>
                <Card.Header>Attendance Trends</Card.Header>
                <Card.Body>
                  <Line data={data} options={options} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <Card.Header>Class Wise Attendance</Card.Header>
                <Card.Body>
                  <Bar data={data} options={options} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AttendanceReport;

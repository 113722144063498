import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Router and Routes
import Dashboard from './components/DashBoard';
import ManageAttendance from './components/StudentManagement/SttudentManagement';
import Classes from './components/ClassManagement/ClassManagement';
import EmployeeList from './components/EmployeeManagement/EmployeeList';
import AddEmployee from './components/EmployeeManagement/AddEmployee';
import DeleteEmployee from './components/EmployeeManagement/DeleteEmployee';
import EditEmployee from './components/EmployeeManagement/EditEmployee';
import VisitorDataTable from './components/VisitorForm/VisitorForm';
import ContactUs from './components/ContactUs/ContactUs';
import BranchCreate from './components/BranchManagement/BranchCreate';
import BranchList from './components/BranchManagement/BranchList';
import AddClass from './components/ClassManagement/AddClasses';
import ViewClasses from './components/ClassManagement/ViewClasses';
import AddStudent from './components/StudentManagement/AddStudent';
import ViewStudents from './components/StudentManagement/ViewStudents';
import StudentDetails from './components/StudentManagement/StudentDetails';
import CreateSubject from './components/SubjectManagement/CreateSubject';
import ViewSubjects from './components/SubjectManagement/ViewSubject';
import StudentAttendance from './components/AttendanceManagement/StudentAttendance';
import EmployeeAttendance from './components/AttendanceManagement/EmployeeAttendance';
import AttendanceReports from './components/AttendanceManagement/AttendanceReport';
import ClassWiseReports from './components/AttendanceManagement/ClassWiseReport';
import StudentAttendanceReport from './components/AttendanceManagement/StudentAttendanceRport';
import EmployeeAttendanceReport from './components/AttendanceManagement/EmployeeAttendanceReport';
import HeaderManagement from './components/WebsiteManagement/WebsiteContentManagement';
import MessagingPage from './components/MessagingPage/MessagingPage';
import BlogManagement from './components/WebsiteManagement/BlogManagement/BlogManagement';
import CreateBlogPostPage from './components/WebsiteManagement/BlogManagement/CreateBlogPost';
import Main from './Main';
import ProtectedRoute from './LoginComponent/ProtectedRoute';
import LoginComponent from './LoginComponent/LoginComponent';
import Sidebar from './components/SideBar';
import TopBar from './components/TopBar/TopBar';
import BranchDashboard from './components/BranchManagement/BranchDashboard';
import AdmissionFormTable from './components/VisitorForm/AdmissionFormTable';
import JobPostingManagement from './components/JobPosting/JobPostingManagement';
import JobPostingForm from './components/JobPosting/JobPostingForm';
import JobPostingReports from './components/JobPosting/JobPostingReports';
import NotificationCenter from './components/NotificationCenter/NotificationCenter';
import ActivityLog from './components/ActivityLogs/ActivityLog';
import ContactUsData from './components/VisitorForm/ContactUsData';
import EditJob from './components/JobPosting/EditJob';
import JobApplicationsPage from './components/JobPosting/JobApplicationsPage';
import RoleManagement from './components/BranchManagement/RoleManagement';



const App = () => {
  return (
    
    <Router> 
      <Routes>
      <Route path="/login" element={<LoginComponent/>} />
      
     
     
        <Route path="/" element={
          <ProtectedRoute>
              <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <Dashboard />
          </div>
        </div>
        </ProtectedRoute>

        


         } />

<Route path="/notification-center" element={
          <ProtectedRoute>
              <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <NotificationCenter />
          </div>
        </div>
        </ProtectedRoute>

        


         } />









        <Route path="/students/add" element={  
<ProtectedRoute>
<TopBar />
<div style={{ display: 'flex', height: '100vh',}}>
<Sidebar />
<div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
<AddStudent />
</div>
</div>
</ProtectedRoute>
} />







        <Route path="/students/view" element={  

<ProtectedRoute>
<TopBar />
<div style={{ display: 'flex', height: '100vh',}}>
<Sidebar />
<div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
<ViewStudents />
</div>
</div>
</ProtectedRoute>
           
           } />




        
<Route path="/students/details/:studentId" element={

<ProtectedRoute>
<TopBar />
<div style={{ display: 'flex', height: '100vh',}}>
<Sidebar />
<div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
<StudentDetails />
</div>
</div>
</ProtectedRoute>
        } />





        <Route path="/classes/add" element={  
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <AddClass />
          </div>
          </div>
          </ProtectedRoute>
          
          } />




        <Route path="/classes/view" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <ViewClasses />
          </div>
          </div>
          </ProtectedRoute>
          
         } />


        <Route path="/employees/view" element={ 
          <ProtectedRoute>
<TopBar />
<div style={{ display: 'flex', height: '100vh',}}>
<Sidebar />
<div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
<EmployeeList />
</div>
</div>
</ProtectedRoute>

            } />




        <Route path="/employees/add" element={  
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <AddEmployee />
          </div>
          </div>
          </ProtectedRoute>
          } />



        <Route path="/employees/edit/:id" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <EditEmployee />
          </div>
          </div>
          </ProtectedRoute>
          
        } />


        <Route path="/employees/delete/:id" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <DeleteEmployee />
          </div>
          </div>
          </ProtectedRoute>  } />


        <Route path="/contact-us/view" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <ContactUs />
          </div>
          </div>
          </ProtectedRoute>  } />


        <Route path="/branches/create" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <BranchCreate />
          </div>
          </div>
          </ProtectedRoute>  } />


        <Route path="/branches" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <BranchList />
          </div>
          </div>
          </ProtectedRoute>  } />


          
        <Route path="/branches/statistics" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <BranchDashboard />
          </div>
          </div>
          </ProtectedRoute>  } />



          <Route path="/branches/role-management" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <RoleManagement />
          </div>
          </div>
          </ProtectedRoute>  } />



        <Route path="/subjects/create" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <CreateSubject />
          </div>
          </div>
          </ProtectedRoute>   } />


        <Route path="/subjects/view" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <ViewSubjects />
          </div>
          </div>
          </ProtectedRoute>   } />


        <Route path="/attendance/student" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <StudentAttendance />
          </div>
          </div>
          </ProtectedRoute> } />


        <Route path="/attendance/employee" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <EmployeeAttendance />
          </div>
          </div>
          </ProtectedRoute>  } />


        <Route path="/attendance/students-report" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <StudentAttendanceReport />
          </div>
          </div>
          </ProtectedRoute>  } />


        <Route path="/attendance/employee-report" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <EmployeeAttendanceReport />
          </div>
          </div>
          </ProtectedRoute> } />



        <Route path="/attendance/report" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <AttendanceReports/>
          </div>
          </div>
          </ProtectedRoute>  } />



        <Route path="/attendance/classwise-report" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <ClassWiseReports />
          </div>
          </div>
          </ProtectedRoute>  } />



        <Route path="/forms/visitor" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <VisitorDataTable />
          </div>
          </div>
          </ProtectedRoute>  } />


          <Route path="/forms/admission-form" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <AdmissionFormTable />
          </div>
          </div>
          </ProtectedRoute>  } />



        <Route path="/website-management/site-info" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <HeaderManagement />
          </div>
          </div>
          </ProtectedRoute>} />



        <Route path="/messages/create" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <MessagingPage />
          </div>
          </div>
          </ProtectedRoute> } />



        <Route path="/website-management/blog/create-blog" element={ 
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <CreateBlogPostPage />
          </div>
          </div>
          </ProtectedRoute>} />


        
        <Route path="/website-management/blog/edit-blog" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <BlogManagement />
          </div>
          </div>
          </ProtectedRoute>} />


          <Route path="/job/create-job" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <JobPostingForm />
          </div>
          </div>
          </ProtectedRoute>} />


          <Route path="/job/edit-job" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <JobPostingManagement />
          </div>
          </div>
          </ProtectedRoute>} />


          <Route  path="/edit-job/:id" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <EditJob />
          </div>
          </div>
          </ProtectedRoute>} />

          <Route path="/job/applications" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <JobApplicationsPage />
          </div>
          </div>
          </ProtectedRoute>} />

          <Route path="/job/job-report" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <JobPostingReports />
          </div>
          </div>
          </ProtectedRoute>} />



          <Route path="/activity-log" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <ActivityLog />
          </div>
          </div>
          </ProtectedRoute>} />     

          
          <Route path="/forms/contact-us-form" element={
          <ProtectedRoute>
          <TopBar />
          <div style={{ display: 'flex', height: '100vh',}}>
          <Sidebar />
          <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
          <ContactUsData/>
          </div>
          </div>
          </ProtectedRoute>} />         

      </Routes>
    </Router>
   
  );
};

export default App;

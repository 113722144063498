import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, InputGroup, FormControl, Card, Spinner, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.min.css';

const ViewStudents = () => {
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  useEffect(() => {
    // Fetch data from backend
    const fetchStudents = async () => {
      try {
        const response = await fetch('https://94.130.56.59:5001/api/students');
        const data = await response.json();
        setStudents(data);
      } catch (error) {
        setAlert({ show: true, message: 'Failed to load student data.', type: 'danger' });
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  const handleDelete = async (id) => {
    try {
      await fetch(`http://localhost:5001/api/students/${id}`, {
        method: 'DELETE',
      });
      setStudents(students.filter(student => student.id !== id));
      setAlert({ show: true, message: 'Student deleted successfully!', type: 'success' });
    } catch (error) {
      setAlert({ show: true, message: 'Failed to delete student.', type: 'danger' });
    }
  };

  const filteredStudents = students.filter(student =>
    student.name.toLowerCase().includes(search.toLowerCase()) ||
    student.className.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Container fluid className="my-4">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', color: '#343a40' }}>Student Directory</h1>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col>
          <InputGroup>
            <FormControl
              placeholder="Search by name or class"
              aria-label="Search"
              aria-describedby="search-addon"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ borderRadius: '0.25rem' }}
            />
          </InputGroup>
        </Col>
      </Row>

      {loading ? (
        <Row className="d-flex justify-content-center">
          <Spinner animation="border" />
        </Row>
      ) : (
        <Row>
          {filteredStudents.length > 0 ? (
            filteredStudents.map((student) => (
              <Col xs={12} sm={6} md={4} lg={3} key={student.id} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title className="text-primary">{student.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Class: {student.className}</Card.Subtitle>
                    <Card.Text>Admission Date: {student.admissionDate}</Card.Text>
                    <div className="d-flex justify-content-between">
                      <Button variant="warning" as={Link} to={`/students/edit/${student._id}`} className="me-2">
                        <EditIcon fontSize="small" /> Edit
                      </Button>
                      <Button variant="danger" onClick={() => handleDelete(student.id)} className="me-2">
                        <DeleteIcon fontSize="small" /> Delete
                      </Button>
                      <Button variant="outline-primary" as={Link} to={`/students/details/${student._id}`}>
                        <VisibilityIcon fontSize="small" /> View
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col className="text-center">
              <p>No students found</p>
            </Col>
          )}
        </Row>
      )}

      <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
        {alert.message}
      </Alert>
    </Container>
  );
};

export default ViewStudents;







// import React, { useState } from 'react';
// import { Table, Button, Container, Row, Col, InputGroup, FormControl, Card, Spinner, Alert } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ViewStudents = () => {
//   const [students, setStudents] = useState([
//     { id: 1, name: 'John Doe', className: 'Class A', admissionDate: '09/08/2024' },
//     { id: 2, name: 'Jane Smith', className: 'Class B', admissionDate: '09/08/2024' },
//     // Add more student data as needed
//   ]);
//   const [search, setSearch] = useState('');

//   const handleDelete = (id) => {
//     setStudents(students.filter(student => student.id !== id));
//   };

//   const filteredStudents = students.filter(student =>
//     student.name.toLowerCase().includes(search.toLowerCase()) ||
//     student.className.toLowerCase().includes(search.toLowerCase())
//   );

//   return (
//     <Container fluid className="my-4">
//       <Row className="mb-4">
//         <Col>
//           <h1 className="text-center mb-4">Student Directory</h1>
//         </Col>
//       </Row>
      
//       <Row className="mb-4">
//         <Col>
//           <InputGroup>
//             <FormControl
//               placeholder="Search by name or class"
//               aria-label="Search"
//               aria-describedby="search-addon"
//               value={search}
//               onChange={(e) => setSearch(e.target.value)}
//             />
//           </InputGroup>
//         </Col>
//       </Row>

//       <Row>
//         {filteredStudents.length > 0 ? (
//           filteredStudents.map((student) => (
//             <Col xs={12} sm={6} md={4} lg={3} key={student.id} className="mb-4">
//               <Card>
//                 <Card.Body>
//                   <Card.Title>{student.name}</Card.Title>
//                   <Card.Subtitle className="mb-2 text-muted">Class: {student.className}</Card.Subtitle>
//                   <Card.Text>Admission Date: {student.admissionDate}</Card.Text>
//                   <div className="d-flex justify-content-between">
//                     <Button variant="warning" as={Link} to={`/students/edit/${student.id}`}>
//                       <EditIcon fontSize="small" /> Edit
//                     </Button>
//                     <Button variant="danger" onClick={() => handleDelete(student.id)}>
//                       <DeleteIcon fontSize="small" /> Delete
//                     </Button>
//                     <Button variant="outline-primary" as={Link} to={`/students/details/${student.id}`}>
//                       <VisibilityIcon fontSize="small" /> View
//                     </Button>
//                   </div>
//                 </Card.Body>
//               </Card>
//             </Col>
//           ))
//         ) : (
//           <Col className="text-center">
//             <p>No students found</p>
//           </Col>
//         )}
//       </Row>
//     </Container>
//   );
// };

// export default ViewStudents;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditJob = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState({
    title: '',
    status: 'Active',
    location: '',
    companyBonus: '',
    transportationBonus: '',
    workingHours: '',
    annualHoliday: '',
    ageRequirement: '',
    genderRequirement: '',
    educationalQualifications: '',
    requiredLicenses: '',
    requiredExperience: '',
    socialInsurance: '',
    dormitory: '',
    mealProvision: '',
    otherAllowances: '',
    desiredQualifications: '',
    japaneseLanguageLevel: '',
    otherLanguages: '',
    jobDetails: '',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get(`https://94.130.56.59:5001/jobs/${id}`);
        setJob(response.data);
      } catch (error) {
        setError('Failed to fetch job details');
      }
    };

    fetchJob();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob({ ...job, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`http://localhost:5001/jobs/${id}`, job);
      setSuccess('Job vacancy updated successfully!');
      setError(null);
      navigate('/jobs'); // Navigate back to the job listing after successful update
    } catch (error) {
      setError('Failed to update job vacancy. Please try again later.');
      setSuccess(null);
    }
  };

  return (
    <Container fluid className="mt-4">
      <Row className="mb-4">
        <Col>
          <h2 className="text-center">Edit Job Vacancy</h2>
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formJobTitle">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter job title"
                name="title"
                value={job.title}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formJobStatus">
              <Form.Label>Job Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={job.status}
                onChange={handleChange}
              >
                <option>Active</option>
                <option>Closed</option>
                <option>Pending</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formLocation">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter location"
                name="location"
                value={job.location}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formCompanyBonus">
              <Form.Label>Company Bonus</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter company bonus details"
                name="companyBonus"
                value={job.companyBonus}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formTransportationBonus">
              <Form.Label>Transportation Bonus</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter transportation bonus details"
                name="transportationBonus"
                value={job.transportationBonus}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formWorkingHours">
              <Form.Label>Working Hours</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter working hours"
                name="workingHours"
                value={job.workingHours}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formAnnualHoliday">
              <Form.Label>Annual Holiday</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter number of holidays per year"
                name="annualHoliday"
                value={job.annualHoliday}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formAgeRequirement">
              <Form.Label>Age Requirement</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter age requirement"
                name="ageRequirement"
                value={job.ageRequirement}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formGenderRequirement">
              <Form.Label>Gender Requirement</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter gender requirement"
                name="genderRequirement"
                value={job.genderRequirement}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEducationalQualifications">
              <Form.Label>Educational Qualifications</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter educational qualifications"
                name="educationalQualifications"
                value={job.educationalQualifications}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formRequiredLicenses">
              <Form.Label>Required Licenses</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter required licenses"
                name="requiredLicenses"
                value={job.requiredLicenses}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formRequiredExperience">
              <Form.Label>Required Experience</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter required experience"
                name="requiredExperience"
                value={job.requiredExperience}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formSocialInsurance">
              <Form.Label>Social Insurance</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter social insurance details"
                name="socialInsurance"
                value={job.socialInsurance}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDormitory">
              <Form.Label>Dormitory</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter dormitory details"
                name="dormitory"
                value={job.dormitory}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formMealProvision">
              <Form.Label>Meal Provision</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter meal provision details"
                name="mealProvision"
                value={job.mealProvision}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formOtherAllowances">
              <Form.Label>Other Allowances</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter other allowances"
                name="otherAllowances"
                value={job.otherAllowances}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formDesiredQualifications">
              <Form.Label>Desired Qualifications</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter desired qualifications"
                name="desiredQualifications"
                value={job.desiredQualifications}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formJapaneseLanguageLevel">
              <Form.Label>Japanese Language Level</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Japanese language level"
                name="japaneseLanguageLevel"
                value={job.japaneseLanguageLevel}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group controlId="formOtherLanguages">
              <Form.Label>Other Languages</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter other languages"
                name="otherLanguages"
                value={job.otherLanguages}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group controlId="formJobDetails">
              <Form.Label>Job Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Enter job details"
                name="jobDetails"
                value={job.jobDetails}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit">
          Update Job Vacancy
        </Button>
      </Form>
    </Container>
  );
};

export default EditJob;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({
    name: '',
    mobileNo: '',
    dateOfJoining: '',
    role: '',
    salary: '',
    fatherName: '',
    nationalId: '',
    education: '',
    gender: '',
    religion: '',
    bloodGroup: '',
    experience: '',
    email: '',
    dateOfBirth: '',
    homeAddress: '',
    profilePicture: null,
  });
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [file, setFile] = useState(null); // State to handle the new profile picture upload

  // Fetch the employee details by ID
  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`https://94.130.56.59:5001/api/employees/${id}`);
        setEmployee(response.data);
        setLoading(false);
      } catch (error) {
        setAlert({ show: true, message: 'Failed to load employee details', type: 'danger' });
        setLoading(false);
      }
    };
    fetchEmployee();
  }, [id]);

  const handleChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.keys(employee).forEach((key) => {
      formData.append(key, employee[key]);
    });

    if (file) {
      formData.append('profilePicture', file); // Attach the profile picture file if uploaded
    }

    try {
      await axios.put(`http://localhost:5001/api/employees/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAlert({ show: true, message: 'Employee updated successfully!', type: 'success' });
      setLoading(false);
      navigate('/employees/view'); // Redirect to employee list after successful edit
    } catch (error) {
      setAlert({ show: true, message: 'Failed to update employee', type: 'danger' });
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Edit Employee</h2>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={employee.name}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formMobileNo" className="mb-3">
                <Form.Label>Mobile No</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNo"
                  value={employee.mobileNo}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={employee.email}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formRole" className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  type="text"
                  name="role"
                  value={employee.role}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formSalary" className="mb-3">
                <Form.Label>Salary</Form.Label>
                <Form.Control
                  type="number"
                  name="salary"
                  value={employee.salary}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formDateOfJoining" className="mb-3">
                <Form.Label>Date of Joining</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfJoining"
                  value={employee.dateOfJoining.split('T')[0]}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formProfilePicture" className="mb-3">
                <Form.Label>Profile Picture</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} className="rounded-pill" />
              </Form.Group>

              <Form.Group controlId="formAddress" className="mb-3">
                <Form.Label>Home Address</Form.Label>
                <Form.Control
                  type="text"
                  name="homeAddress"
                  value={employee.homeAddress}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formFatherName" className="mb-3">
                <Form.Label>Father's Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fatherName"
                  value={employee.fatherName}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit" className="px-5 rounded-pill">
              Update Employee
            </Button>
          </div>

          <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ show: false, message: '', type: '' })} dismissible className="mt-4">
            {alert.message}
          </Alert>
        </Form>
      )}
    </Container>
  );
};

export default EditEmployee;

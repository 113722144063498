import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';

const AddEmployeeForm = () => {
  const [employee, setEmployee] = useState({
    name: '',
    mobileNo: '',
    dateOfJoining: '',
    role: '',
    salary: '',
    fatherName: '',
    nationalId: '',
    education: '',
    gender: '',
    religion: '',
    bloodGroup: '',
    experience: '',
    email: '',
    dateOfBirth: '',
    homeAddress: '',
    profilePicture: null
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee({ ...employee, [name]: value });
  };

  const handleFileChange = (e) => {
    setEmployee({ ...employee, profilePicture: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setLoading(true);
      const formData = new FormData();
      for (const key in employee) {
        formData.append(key, employee[key]);
      }

      try {
        const response = await axios.post('https://94.130.56.59:5001/api/employees', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setSuccess(true);
        setError(null);
        setLoading(false);
      } catch (error) {
        setError('Failed to add employee. Please try again.');
        setLoading(false);
        setSuccess(false);
      }
    }
    setValidated(true);
  };

  return (
    <Container>
      <Card className="my-4">
        <Card.Header as="h5">Add New Employee</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            {/* Basic Information */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formEmployeeName">
                <Form.Label>Employee Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={employee.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Employee name is required.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formMobileNo">
                <Form.Label>Mobile No*</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNo"
                  value={employee.mobileNo}
                  onChange={handleChange}
                  pattern="\d{10}"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid 10-digit mobile number.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formDateOfJoining">
                <Form.Label>Date of Joining*</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfJoining"
                  value={employee.dateOfJoining}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select a joining date.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formRole">
                <Form.Label>Employee Role*</Form.Label>
                <Form.Control
                  type="text"
                  name="role"
                  value={employee.role}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Role is required.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formSalary">
                <Form.Label>Monthly Salary*</Form.Label>
                <Form.Control
                  type="number"
                  name="salary"
                  value={employee.salary}
                  onChange={handleChange}
                  min="0"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please input a valid salary.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formProfilePicture">
                <Form.Label>Profile Picture (Max 100KB)</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please upload a valid image.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            {/* Gender Dropdown */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formGender">
                <Form.Label>Gender*</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={employee.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a gender.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formBloodGroup">
                <Form.Label>Blood Group</Form.Label>
                <Form.Control
                  as="select"
                  name="bloodGroup"
                  value={employee.bloodGroup}
                  onChange={handleChange}
                >
                  <option value="">Select Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </Form.Control>
              </Form.Group>
            </Row>

            {/* Additional Information */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formFatherName">
                <Form.Label>Father's Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fatherName"
                  value={employee.fatherName}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter father's name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formNationalId">
                <Form.Label>National ID</Form.Label>
                <Form.Control
                  type="text"
                  name="nationalId"
                  value={employee.nationalId}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter national ID.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formReligion">
                <Form.Label>Religion</Form.Label>
                <Form.Control
                  type="text"
                  name="religion"
                  value={employee.religion}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formEducation">
                <Form.Label>Education</Form.Label>
                <Form.Control
                  type="text"
                  name="education"
                  value={employee.education}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formExperience">
                <Form.Label>Experience</Form.Label>
                <Form.Control
                  type="text"
                  name="experience"
                  value={employee.experience}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formEmail">
                <Form.Label>Email Address*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={employee.email}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formDateOfBirth">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfBirth"
                  value={employee.dateOfBirth}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formHomeAddress">
                <Form.Label>Home Address</Form.Label>
                <Form.Control
                  type="text"
                  name="homeAddress"
                  value={employee.homeAddress}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
            {success && <div className="mt-3 text-success">Employee added successfully!</div>}
            {error && <div className="mt-3 text-danger">{error}</div>}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddEmployeeForm;




// // src/components/AddEmployeeForm.jsx
// import React, { useState } from 'react';
// import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';

// const AddEmployeeForm = () => {
//   const [employee, setEmployee] = useState({
//     name: '',
//     mobileNo: '',
//     dateOfJoining: '',
//     role: '',
//     salary: '',
//     fatherName: '',
//     nationalId: '',
//     education: '',
//     gender: '',
//     religion: '',
//     bloodGroup: '',
//     experience: '',
//     email: '',
//     dateOfBirth: '',
//     homeAddress: '',
//     profilePicture: null
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setEmployee({ ...employee, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     setEmployee({ ...employee, profilePicture: e.target.files[0] });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission
//     console.log('Employee Added:', employee);
//   };

//   return (
//     <Container>
//       <Card className="my-4">
//         <Card.Header as="h5">Add New Employee</Card.Header>
//         <Card.Body>
//           <Form onSubmit={handleSubmit}>
//             {/* Basic Information */}
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formEmployeeName">
//                 <Form.Label>Employee Name*</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   value={employee.name}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formMobileNo">
//                 <Form.Label>Mobile No</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="mobileNo"
//                   value={employee.mobileNo}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Row>
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formDateOfJoining">
//                 <Form.Label>Date of Joining*</Form.Label>
//                 <Form.Control
//                   type="date"
//                   name="dateOfJoining"
//                   value={employee.dateOfJoining}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formRole">
//                 <Form.Label>Employee Role*</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="role"
//                   value={employee.role}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//             </Row>
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formSalary">
//                 <Form.Label>Monthly Salary*</Form.Label>
//                 <Form.Control
//                   type="number"
//                   name="salary"
//                   value={employee.salary}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formProfilePicture">
//                 <Form.Label>Profile Picture (Max 100KB)</Form.Label>
//                 <Form.Control
//                   type="file"
//                   accept="image/*"
//                   onChange={handleFileChange}
//                 />
//               </Form.Group>
//             </Row>

//             {/* Other Information */}
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formFatherName">
//                 <Form.Label>Father/Husband Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="fatherName"
//                   value={employee.fatherName}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formNationalId">
//                 <Form.Label>National ID</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="nationalId"
//                   value={employee.nationalId}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Row>
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formEducation">
//                 <Form.Label>Education</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="education"
//                   value={employee.education}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formGender">
//                 <Form.Label>Gender</Form.Label>
//                 <Form.Control
//                   as="select"
//                   name="gender"
//                   value={employee.gender}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Gender</option>
//                   <option value="Male">Male</option>
//                   <option value="Female">Female</option>
//                 </Form.Control>
//               </Form.Group>
//             </Row>
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formReligion">
//                 <Form.Label>Religion</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="religion"
//                   value={employee.religion}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formBloodGroup">
//                 <Form.Label>Blood Group</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="bloodGroup"
//                   value={employee.bloodGroup}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Row>

//             {/* Additional Information */}
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formExperience">
//                 <Form.Label>Experience</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="experience"
//                   value={employee.experience}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formEmail">
//                 <Form.Label>Email Address</Form.Label>
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   value={employee.email}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Row>
//             <Row className="mb-3">
//               <Form.Group as={Col} md="6" controlId="formDateOfBirth">
//                 <Form.Label>Date of Birth</Form.Label>
//                 <Form.Control
//                   type="date"
//                   name="dateOfBirth"
//                   value={employee.dateOfBirth}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//               <Form.Group as={Col} md="6" controlId="formHomeAddress">
//                 <Form.Label>Home Address</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="homeAddress"
//                   value={employee.homeAddress}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Row>

//             <Button variant="primary" type="submit">Add Employee</Button>
//           </Form>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default AddEmployeeForm;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, ListGroup, Alert } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';

// Sample data for charts
const sampleData = [
  { name: 'Jan', sales: 4000 },
  { name: 'Feb', sales: 3000 },
  { name: 'Mar', sales: 5000 },
  { name: 'Apr', sales: 6000 },
  { name: 'May', sales: 7000 },
  { name: 'Jun', sales: 8000 },
];

const Dashboard = () => {
  const [data, setData] = useState({
    totalStudents: 0,
    totalEmployees: 0,
    revenue: 0,
    totalProfit: 0,
    monthlyData: sampleData,
    feeData: { estimation: 0, collections: 0, remainings: 0 }
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:5001/api/dashboard');
        setData({
          totalStudents: response.data.totalStudents,
          totalEmployees: response.data.totalEmployees,
          revenue: response.data.revenue,
          totalProfit: response.data.totalProfit,
          monthlyData: response.data.monthlyData,
          feeData: response.data.feeData
        });
        setError(null); // Clear any previous errors
      } catch (err) {
        setError('Failed to load dashboard data.');
      }
    };

    fetchData();
  }, []);

  return (
    <Container fluid className="p-4">
      {/* Error Message */}
      {error && (
        <Row className="mb-4">
          <Col>
            <Alert variant="danger">
              {error}
            </Alert>
          </Col>
        </Row>
      )}

      {/* Dashboard Title */}
      <Row className="mb-4">
        <Col>
          <h1 className="text-center text-primary">Admin Dashboard</h1>
        </Col>
      </Row>

      {/* Statistic Cards */}
      <Row className="mb-4">
        <Col md={3}>
          <Card className="border-success shadow-sm">
            <Card.Header className="bg-success text-white">Total Students</Card.Header>
            <Card.Body className="text-center">
              <h2 className="text-success">{data.totalStudents}</h2>
              <p>This Month: {data.totalStudents}</p>
              <Button variant="outline-success">View Details</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="border-danger shadow-sm">
            <Card.Header className="bg-danger text-white">Total Employees</Card.Header>
            <Card.Body className="text-center">
              <h2 className="text-danger">{data.totalEmployees}</h2>
              <p>This Month: {data.totalEmployees}</p>
              <Button variant="outline-danger">View Details</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="border-warning shadow-sm">
            <Card.Header className="bg-warning text-dark">Revenue</Card.Header>
            <Card.Body className="text-center">
              <h2 className="text-warning">${data.revenue}</h2>
              <p>This Month: ${data.revenue}</p>
              <Button variant="outline-warning">View Details</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="border-info shadow-sm">
            <Card.Header className="bg-info text-white">Total Profit</Card.Header>
            <Card.Body className="text-center">
              <h2 className="text-info">${data.totalProfit}</h2>
              <p>This Month: ${data.totalProfit}</p>
              <Button variant="outline-info">View Details</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Today's Data */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header className="text-danger">Today's Absent Students</Card.Header>
            <Card.Body>
              <p className="text-center">Attendance Not Marked Yet!</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header className="text-primary">Today's Present Employees</Card.Header>
            <Card.Body>
              <p className="text-center">Attendance Not Marked Yet!</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Today's Admissions */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header className="text-success">Today's Admissions</Card.Header>
            <Card.Body>
              <h3 className="text-center text-primary">0</h3>
              <p className="text-center">No new admissions today.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* New Admissions & Fee Collection */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header>New Admissions</Card.Header>
            <Card.Body>
              <p>No New Admissions This Month</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header>Estimated Fee This Month</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {/* <ListGroup.Item>Estimation: ${data.feeData.estimation}</ListGroup.Item>
                <ListGroup.Item>Collections: ${data.feeData.collections}</ListGroup.Item>
                <ListGroup.Item>Remainings: ${data.feeData.remainings}</ListGroup.Item> */}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* SMS Gateway */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Body>
              <Card.Title>Free SMS Gateway</Card.Title>
              <Card.Text>
                Send Unlimited Free SMS on Mobile Numbers.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Calendar Section */}
      <Row className="mb-4">
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header>Calendar</Card.Header>
            <Card.Body>
              <Calendar
                view="month"
                style={{ border: '1px solid #ddd', borderRadius: '8px' }}
              />
            </Card.Body>
          </Card>
        </Col>
        
        {/* Sales Overview Chart */}
        <Col md={6}>
          <Card className="bg-light shadow-sm">
            <Card.Header>Sales Overview</Card.Header>
            <Card.Body>
              <BarChart width={600} height={300} data={data.monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="sales" fill="#1976d2" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;











// // src/components/Dashboard.jsx
// import React from 'react';
// import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';

// // Sample data for charts
// const data = [
//   { name: 'Jan', sales: 4000 },
//   { name: 'Feb', sales: 3000 },
//   { name: 'Mar', sales: 5000 },
//   { name: 'Apr', sales: 6000 },
//   { name: 'May', sales: 7000 },
//   { name: 'Jun', sales: 8000 },
// ];

// const Dashboard = () => {
//   return (
//     <Container fluid className="p-4">
//       {/* Dashboard Title */}
//       <Row className="mb-4">
//         <Col>
//           <h1 className="text-center text-primary">Admin Dashboard</h1>
//         </Col>
//       </Row>

//       {/* Statistic Cards */}
//       <Row className="mb-4">
//         <Col md={3}>
//           <Card className="border-success shadow-sm">
//             <Card.Header className="bg-success text-white">Total Students</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-success">0</h2>
//               <p>This Month: 0</p>
//               <Button variant="outline-success">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={3}>
//           <Card className="border-danger shadow-sm">
//             <Card.Header className="bg-danger text-white">Total Employees</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-danger">0</h2>
//               <p>This Month: 0</p>
//               <Button variant="outline-danger">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={3}>
//           <Card className="border-warning shadow-sm">
//             <Card.Header className="bg-warning text-dark">Revenue</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-warning">$0</h2>
//               <p>This Month: $0</p>
//               <Button variant="outline-warning">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={3}>
//           <Card className="border-info shadow-sm">
//             <Card.Header className="bg-info text-white">Total Profit</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-info">$0</h2>
//               <p>This Month: $0</p>
//               <Button variant="outline-info">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Today's Data */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header className="text-danger">Today's Absent Students</Card.Header>
//             <Card.Body>
//               <p className="text-center">Attendance Not Marked Yet!</p>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header className="text-primary">Today's Present Employees</Card.Header>
//             <Card.Body>
//               <p className="text-center">Attendance Not Marked Yet!</p>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Today's Admissions */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header className="text-success">Today's Admissions</Card.Header>
//             <Card.Body>
//               <h3 className="text-center text-primary">0</h3>
//               <p className="text-center">No new admissions today.</p>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* New Admissions & Fee Collection */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>New Admissions</Card.Header>
//             <Card.Body>
//               <p>No New Admissions This Month</p>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Estimated Fee This Month</Card.Header>
//             <Card.Body>
//               <ListGroup variant="flush">
//                 <ListGroup.Item>Estimation: $0</ListGroup.Item>
//                 <ListGroup.Item>Collections: $0</ListGroup.Item>
//                 <ListGroup.Item>Remainings: $0</ListGroup.Item>
//               </ListGroup>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* SMS Gateway */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Body>
//               <Card.Title>Free SMS Gateway</Card.Title>
//               <Card.Text>
//                 Send Unlimited Free SMS on Mobile Numbers.
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Calendar Section */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Calendar</Card.Header>
//             <Card.Body>
//               <Calendar
//                 view="month"
//                 style={{ border: '1px solid #ddd', borderRadius: '8px' }}
//               />
//             </Card.Body>
//           </Card>
//         </Col>
        
//         {/* Sales Overview Chart */}
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Sales Overview</Card.Header>
//             <Card.Body>
//               <BarChart width={600} height={300} data={data}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Bar dataKey="sales" fill="#1976d2" />
//               </BarChart>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default Dashboard;


//dynamic api based

// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import axios from 'axios';

// // Dashboard Component
// const Dashboard = () => {
//   const [dashboardData, setDashboardData] = useState({
//     totalStudents: 0,
//     totalEmployees: 0,
//     revenue: 0,
//     profit: 0,
//     todayAbsentStudents: 0,
//     todayPresentEmployees: 0,
//     todaysAdmissions: 0,
//     newAdmissions: 0,
//     feeEstimation: 0,
//     feeCollection: 0,
//     feeRemaining: 0,
//     salesData: [],
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Fetch data from backend
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('/api/dashboard');
//         setDashboardData(response.data);
//         setLoading(false);
//       } catch (error) {
//         setError('Error fetching data');
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <Container fluid className="p-4">
//       {/* Dashboard Title */}
//       <Row className="mb-4">
//         <Col>
//           <h1 className="text-center text-primary">Admin Dashboard</h1>
//         </Col>
//       </Row>

//       {/* Statistic Cards */}
//       <Row className="mb-4">
//         <Col md={3}>
//           <Card className="border-success shadow-sm">
//             <Card.Header className="bg-success text-white">Total Students</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-success">{dashboardData.totalStudents}</h2>
//               <p>This Month: {dashboardData.totalStudents}</p>
//               <Button variant="outline-success">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={3}>
//           <Card className="border-danger shadow-sm">
//             <Card.Header className="bg-danger text-white">Total Employees</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-danger">{dashboardData.totalEmployees}</h2>
//               <p>This Month: {dashboardData.totalEmployees}</p>
//               <Button variant="outline-danger">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={3}>
//           <Card className="border-warning shadow-sm">
//             <Card.Header className="bg-warning text-dark">Revenue</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-warning">${dashboardData.revenue}</h2>
//               <p>This Month: ${dashboardData.revenue}</p>
//               <Button variant="outline-warning">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={3}>
//           <Card className="border-info shadow-sm">
//             <Card.Header className="bg-info text-white">Total Profit</Card.Header>
//             <Card.Body className="text-center">
//               <h2 className="text-info">${dashboardData.profit}</h2>
//               <p>This Month: ${dashboardData.profit}</p>
//               <Button variant="outline-info">View Details</Button>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Today's Data */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header className="text-danger">Today's Absent Students</Card.Header>
//             <Card.Body>
//               <p className="text-center">{dashboardData.todayAbsentStudents === 0 ? 'Attendance Not Marked Yet!' : dashboardData.todayAbsentStudents}</p>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header className="text-primary">Today's Present Employees</Card.Header>
//             <Card.Body>
//               <p className="text-center">{dashboardData.todayPresentEmployees === 0 ? 'Attendance Not Marked Yet!' : dashboardData.todayPresentEmployees}</p>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Today's Admissions */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header className="text-success">Today's Admissions</Card.Header>
//             <Card.Body>
//               <h3 className="text-center text-primary">{dashboardData.todaysAdmissions}</h3>
//               <p className="text-center">No new admissions today.</p>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* New Admissions & Fee Collection */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>New Admissions</Card.Header>
//             <Card.Body>
//               <p>{dashboardData.newAdmissions > 0 ? `${dashboardData.newAdmissions} New Admissions This Month` : 'No New Admissions This Month'}</p>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Estimated Fee This Month</Card.Header>
//             <Card.Body>
//               <ListGroup variant="flush">
//                 <ListGroup.Item>Estimation: ${dashboardData.feeEstimation}</ListGroup.Item>
//                 <ListGroup.Item>Collections: ${dashboardData.feeCollection}</ListGroup.Item>
//                 <ListGroup.Item>Remainings: ${dashboardData.feeRemaining}</ListGroup.Item>
//               </ListGroup>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* SMS Gateway */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Body>
//               <Card.Title>Free SMS Gateway</Card.Title>
//               <Card.Text>
//                 Send Unlimited Free SMS on Mobile Numbers.
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {/* Calendar Section */}
//       <Row className="mb-4">
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Calendar</Card.Header>
//             <Card.Body>
//               <Calendar
//                 view="month"
//                 style={{ border: '1px solid #ddd', borderRadius: '8px' }}
//               />
//             </Card.Body>
//           </Card>
//         </Col>

//         {/* Sales Overview Chart */}
//         <Col md={6}>
//           <Card className="bg-light shadow-sm">
//             <Card.Header>Sales Overview</Card.Header>
//             <Card.Body>
//               <BarChart width={600} height={300} data={dashboardData.salesData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Bar dataKey="sales" fill="#1976d2" />
//               </BarChart>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default Dashboard;


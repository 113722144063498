import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, InputGroup, FormControl } from 'react-bootstrap';

const WebsiteContentManagement = () => {
  const [settings, setSettings] = useState({
    logo: '',
    websiteName: '',
    websiteTitle: '',
    websiteDescription: '',
    metaContents: '',
    keywords: '',
    termsAndPolicies: '',
    footer: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      linkedin: '',
    },
    telephoneNo: '',
    header: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleSocialLinkChange = (e) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      socialLinks: { ...settings.socialLinks, [name]: value },
    });
  };

  const handleLogoChange = (e) => {
    setSettings({ ...settings, logo: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(settings); // Handle form submission (e.g., API call)
  };

  return (
    <Container fluid className="mt-4">
      <Card className="p-4 shadow-sm">
        <h2 className="text-center text-primary mb-4">Website Content Management</h2>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formLogo">
                <Form.Label className="text-info">Website Logo</Form.Label>
                <Form.Control type="file" onChange={handleLogoChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formWebsiteName">
                <Form.Label className="text-info">Website Name</Form.Label>
                <Form.Control
                  type="text"
                  name="websiteName"
                  value={settings.websiteName}
                  onChange={handleChange}
                  placeholder="Enter website name"
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formWebsiteTitle">
                <Form.Label className="text-info">Website Title</Form.Label>
                <Form.Control
                  type="text"
                  name="websiteTitle"
                  value={settings.websiteTitle}
                  onChange={handleChange}
                  placeholder="Enter website title"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formWebsiteDescription">
                <Form.Label className="text-info">Website Description</Form.Label>
                <Form.Control
                  type="text"
                  name="websiteDescription"
                  value={settings.websiteDescription}
                  onChange={handleChange}
                  placeholder="Enter website description"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="formMetaContents">
                <Form.Label className="text-info">Meta Contents</Form.Label>
                <Form.Control
                  as="textarea"
                  name="metaContents"
                  value={settings.metaContents}
                  onChange={handleChange}
                  placeholder="Enter meta contents"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formKeywords">
                <Form.Label className="text-info">Keywords</Form.Label>
                <Form.Control
                  type="text"
                  name="keywords"
                  value={settings.keywords}
                  onChange={handleChange}
                  placeholder="Enter keywords"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formTelephoneNo">
                <Form.Label className="text-info">Telephone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="telephoneNo"
                  value={settings.telephoneNo}
                  onChange={handleChange}
                  placeholder="Enter telephone number"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="formTermsAndPolicies">
                <Form.Label className="text-info">Terms and Policies</Form.Label>
                <Form.Control
                  as="textarea"
                  name="termsAndPolicies"
                  value={settings.termsAndPolicies}
                  onChange={handleChange}
                  placeholder="Enter terms and policies"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="formFooter">
                <Form.Label className="text-info">Footer</Form.Label>
                <Form.Control
                  as="textarea"
                  name="footer"
                  value={settings.footer}
                  onChange={handleChange}
                  placeholder="Enter footer content"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formHeader">
                <Form.Label className="text-info">Header</Form.Label>
                <Form.Control
                  type="text"
                  name="header"
                  value={settings.header}
                  onChange={handleChange}
                  placeholder="Enter header content"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Label className="text-info">Social Links</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  type="text"
                  name="facebook"
                  value={settings.socialLinks.facebook}
                  onChange={handleSocialLinkChange}
                  placeholder="Facebook URL"
                />
                <FormControl
                  type="text"
                  name="twitter"
                  value={settings.socialLinks.twitter}
                  onChange={handleSocialLinkChange}
                  placeholder="Twitter URL"
                />
                <FormControl
                  type="text"
                  name="linkedin"
                  value={settings.socialLinks.linkedin}
                  onChange={handleSocialLinkChange}
                  placeholder="LinkedIn URL"
                />
              </InputGroup>
            </Col>
          </Row>

          <Button type="submit" variant="primary" className="w-100">
            Save Changes
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default WebsiteContentManagement;

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddStudent = () => {
  const [studentData, setStudentData] = useState({
    name: '',
    picture: '',
    registrationNo: '',
    admissionDate: '',
    selectedClass: '',
    discount: '',
    mobileNo: '',
    dob: '',
    gender: '',
    idMark: '',
    bloodGroup: '',
    disease: '',
    birthId: '',
    cast: '',
    prevSchool: '',
    prevId: '',
    additionalNote: '',
    orphan: '',
    osc: '',
    religion: '',
    family: '',
    siblings: '',
    address: '',
    fatherName: '',
    fatherEducation: '',
    fatherId: '',
    fatherMobile: '',
    fatherOccupation: '',
    fatherProfession: '',
    fatherIncome: '',
    motherName: '',
    motherEducation: '',
    motherId: '',
    motherMobile: '',
    motherOccupation: '',
    motherProfession: '',
    motherIncome: ''
  });

  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  const handleChange = (e) => {
    setStudentData({ ...studentData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setStudentData({ ...studentData, picture: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to handle file uploads
    const formData = new FormData();
    for (const key in studentData) {
      formData.append(key, studentData[key]);
    }

    try {
      const response = await fetch('https://94.130.56.59:5001/api/students', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result);
      setAlert({ show: true, message: 'Student data submitted successfully!', type: 'success' });
      setStudentData({
        name: '',
        picture: '',
        registrationNo: '',
        admissionDate: '',
        selectedClass: '',
        discount: '',
        mobileNo: '',
        dob: '',
        gender: '',
        idMark: '',
        bloodGroup: '',
        disease: '',
        birthId: '',
        cast: '',
        prevSchool: '',
        prevId: '',
        additionalNote: '',
        orphan: '',
        osc: '',
        religion: '',
        family: '',
        siblings: '',
        address: '',
        fatherName: '',
        fatherEducation: '',
        fatherId: '',
        fatherMobile: '',
        fatherOccupation: '',
        fatherProfession: '',
        fatherIncome: '',
        motherName: '',
        motherEducation: '',
        motherId: '',
        motherMobile: '',
        motherOccupation: '',
        motherProfession: '',
        motherIncome: ''
      });
      
    } catch (error) {
      console.error('Error:', error);
      setAlert({ show: true, message: 'Failed to submit student data.', type: 'danger' });
    }
  };

  return (
    <Container className="my-5">
      <Card>
        <Card.Body>
          <Card.Title className="text-center mb-4">Admission Form</Card.Title>

          {alert.show && <Alert variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
            {alert.message}
          </Alert>}

          <Form onSubmit={handleSubmit}>
            <Row className="mb-4">
              {/* Student Information */}
              <Col xs={12}>
                <h5 className="text-primary">Student Information</h5>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Student Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={studentData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formPicture">
                  <Form.Label>Picture (Optional)</Form.Label>
                  <Form.Control
                    type="file"
                    name="picture"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formRegistrationNo">
                  <Form.Label>Registration No</Form.Label>
                  <Form.Control
                    type="text"
                    name="registrationNo"
                    value={studentData.registrationNo}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formAdmissionDate">
                  <Form.Label>Date of Admission*</Form.Label>
                  <Form.Control
                    type="date"
                    name="admissionDate"
                    value={studentData.admissionDate}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formSelectedClass">
                  <Form.Label>Select Class*</Form.Label>
                  <Form.Control
                    type="text"
                    name="selectedClass"
                    value={studentData.selectedClass}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDiscount">
                  <Form.Label>Discount In Fee*</Form.Label>
                  <Form.Control
                    type="number"
                    name="discount"
                    value={studentData.discount}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMobileNo">
                  <Form.Label>Mobile No. for SMS/WhatsApp</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobileNo"
                    value={studentData.mobileNo}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              {/* Other Information */}
              <Col xs={12}>
                <h5 className="text-primary">Other Information</h5>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDob">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={studentData.dob}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    type="text"
                    name="gender"
                    value={studentData.gender}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formIdMark">
                  <Form.Label>Any Identification Mark?</Form.Label>
                  <Form.Control
                    type="text"
                    name="idMark"
                    value={studentData.idMark}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formBloodGroup">
                  <Form.Label>Blood Group</Form.Label>
                  <Form.Control
                    type="text"
                    name="bloodGroup"
                    value={studentData.bloodGroup}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDisease">
                  <Form.Label>Disease If Any?</Form.Label>
                  <Form.Control
                    type="text"
                    name="disease"
                    value={studentData.disease}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formBirthId">
                  <Form.Label>Student Birth Form ID / NIC</Form.Label>
                  <Form.Control
                    type="text"
                    name="birthId"
                    value={studentData.birthId}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formCast">
                  <Form.Label>Cast</Form.Label>
                  <Form.Control
                    type="text"
                    name="cast"
                    value={studentData.cast}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formPrevSchool">
                  <Form.Label>Previous School</Form.Label>
                  <Form.Control
                    type="text"
                    name="prevSchool"
                    value={studentData.prevSchool}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formPrevId">
                  <Form.Label>Previous ID / Board Roll No</Form.Label>
                  <Form.Control
                    type="text"
                    name="prevId"
                    value={studentData.prevId}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formAdditionalNote">
                  <Form.Label>Any Additional Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="additionalNote"
                    value={studentData.additionalNote}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formOrphan">
                  <Form.Label>Orphan Student</Form.Label>
                  <Form.Control
                    type="text"
                    name="orphan"
                    value={studentData.orphan}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formOsc">
                  <Form.Label>OSC</Form.Label>
                  <Form.Control
                    type="text"
                    name="osc"
                    value={studentData.osc}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formReligion">
                  <Form.Label>Religion</Form.Label>
                  <Form.Control
                    type="text"
                    name="religion"
                    value={studentData.religion}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFamily">
                  <Form.Label>Family Background</Form.Label>
                  <Form.Control
                    type="text"
                    name="family"
                    value={studentData.family}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formSiblings">
                  <Form.Label>Number of Siblings</Form.Label>
                  <Form.Control
                    type="number"
                    name="siblings"
                    value={studentData.siblings}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={studentData.address}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              {/* Father’s Information */}
              <Col xs={12}>
                <h5 className="text-primary">Father's Information</h5>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFatherName">
                  <Form.Label>Father’s Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherName"
                    value={studentData.fatherName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFatherEducation">
                  <Form.Label>Father’s Education</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherEducation"
                    value={studentData.fatherEducation}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFatherId">
                  <Form.Label>Father’s ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherId"
                    value={studentData.fatherId}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFatherMobile">
                  <Form.Label>Father’s Mobile No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherMobile"
                    value={studentData.fatherMobile}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFatherOccupation">
                  <Form.Label>Father’s Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherOccupation"
                    value={studentData.fatherOccupation}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFatherProfession">
                  <Form.Label>Father’s Profession</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherProfession"
                    value={studentData.fatherProfession}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFatherIncome">
                  <Form.Label>Father’s Income</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherIncome"
                    value={studentData.fatherIncome}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              {/* Mother’s Information */}
              <Col xs={12}>
                <h5 className="text-primary">Mother's Information</h5>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMotherName">
                  <Form.Label>Mother’s Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherName"
                    value={studentData.motherName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMotherEducation">
                  <Form.Label>Mother’s Education</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherEducation"
                    value={studentData.motherEducation}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMotherId">
                  <Form.Label>Mother’s ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherId"
                    value={studentData.motherId}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMotherMobile">
                  <Form.Label>Mother’s Mobile No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherMobile"
                    value={studentData.motherMobile}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMotherOccupation">
                  <Form.Label>Mother’s Occupation</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherOccupation"
                    value={studentData.motherOccupation}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMotherProfession">
                  <Form.Label>Mother’s Profession</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherProfession"
                    value={studentData.motherProfession}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMotherIncome">
                  <Form.Label>Mother’s Income</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherIncome"
                    value={studentData.motherIncome}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} className="text-center">
                <Button variant="primary" type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddStudent;

import React, { useState, useEffect, useMemo } from 'react';
import { Table, Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import axios from 'axios';

// API Function
const fetchSubmissions = async () => {
  try {
    const response = await axios.get('https://94.130.56.59:5001/contact-form-submissions');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const ContactUsData = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  // Fetch initial data
  useEffect(() => {
    const loadData = async () => {
      try {
        const submissions = await fetchSubmissions();
        setData(submissions);
        setError(null); // Clear any previous error
      } catch (error) {
        setError('Failed to fetch contact form submissions. Please try again later.');
      }
    };
    loadData();
  }, []);

  const columns = useMemo(() => [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Telephone Number', accessor: 'telephone' },
    { Header: 'Subject', accessor: 'subject' },
    { Header: 'Contents of Inquiry', accessor: 'inquiry' },
    { Header: 'Message', accessor: 'message' },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    { columns, data },
    useSortBy
  );

  return (
    <Container fluid className="mt-4">
      <Row className="mb-3">
        <Col>
          <h2 className="text-center text-primary mb-4">Contact Form Submissions</h2>
        </Col>
      </Row>
      {error && (
        <Row className="mb-3">
          <Col>
            <Alert variant="danger">
              {error}
            </Alert>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Header className="bg-primary text-white">
          <h4 className="mb-0">Submitted Forms</h4>
        </Card.Header>
        <Card.Body>
          <Table {...getTableProps()} striped bordered hover responsive>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ContactUsData;


// import React, { useMemo } from 'react';
// import { Table, Container, Row, Col, Card } from 'react-bootstrap';
// import { useTable, useSortBy } from 'react-table';

// // Sample data for contact form submissions
// const sampleSubmissions = [
//   {
//     id: 1,
//     name: 'Alice Johnson',
//     email: 'alice.johnson@example.com',
//     telephone: '123-456-7890',
//     subject: 'About Recruitment',
//     inquiry: 'Interested in j ob opportunities.',
//     message: 'I would like to know more about the recruitment process.',
//   },
//   {
//     id: 2,
//     name: 'Bob Smith', 
//     email: 'bob.smith@example.com',
//     telephone: '987-654-3210',
//     subject: 'About Online Class',
//     inquiry: 'Requesting information about online classes.',
//     message: 'Could you provide more details about the online class schedule?',
//   },
//   // Add more submissions as needed
// ];

// const ContactUsData = () => {
//   const columns = useMemo(() => [
//     { Header: 'Name', accessor: 'name' },
//     { Header: 'Email', accessor: 'email' },
//     { Header: 'Telephone Number', accessor: 'telephone' },
//     { Header: 'Subject', accessor: 'subject' },
//     { Header: 'Contents of Inquiry', accessor: 'inquiry' },
//     { Header: 'Message', accessor: 'message' },
//   ], []);

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow
//   } = useTable(
//     { columns, data: sampleSubmissions },
//     useSortBy
//   );

//   return (
//     <Container fluid className="mt-4">
//       <Row className="mb-3">
//         <Col>
//           <h2 className="text-center text-primary mb-4">Contact Form Submissions</h2>
//         </Col>
//       </Row>
//       <Card>
//         <Card.Header className="bg-primary text-white">
//           <h4 className="mb-0">Submitted Forms</h4>
//         </Card.Header>
//         <Card.Body>
//           <Table {...getTableProps()} striped bordered hover responsive>
//             <thead>
//               {headerGroups.map(headerGroup => (
//                 <tr {...headerGroup.getHeaderGroupProps()}>
//                   {headerGroup.headers.map(column => (
//                     <th {...column.getHeaderProps(column.getSortByToggleProps())}>
//                       {column.render('Header')}
//                       <span>
//                         {column.isSorted
//                           ? column.isSortedDesc
//                             ? ' 🔽'
//                             : ' 🔼'
//                           : ''}
//                       </span>
//                     </th>
//                   ))}
//                 </tr>
//               ))}
//             </thead>
//             <tbody {...getTableBodyProps()}>
//               {rows.map(row => {
//                 prepareRow(row);
//                 return (
//                   <tr {...row.getRowProps()}>
//                     {row.cells.map(cell => (
//                       <td {...cell.getCellProps()}>
//                         {cell.render('Cell')}
//                       </td>
//                     ))}
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </Table>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ContactUsData;

// src/components/AttendanceManagement/StudentAttendance.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Paper, Grid, Box, MenuItem } from '@mui/material'; // Ensure MenuItem is imported
import { deepPurple } from '@mui/material/colors';

const StudentAttendance = () => {
  const [attendance, setAttendance] = useState({
    studentName: '',
    date: '',
    status: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttendance({ ...attendance, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(attendance);
  };

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ color: deepPurple[700] }}>
          Student Attendance
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Student Name"
                name="studentName"
                value={attendance.studentName}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Date"
                name="date"
                type="date"
                value={attendance.date}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Status"
                name="status"
                value={attendance.status}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
                sx={{ mb: 2 }}
              >
                <MenuItem value="Present">Present</MenuItem>
                <MenuItem value="Absent">Absent</MenuItem>
                {/* Add more status options if needed */}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" sx={{ width: '100%' }}>
                Save Attendance
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default StudentAttendance;

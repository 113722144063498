import React, { useState } from 'react';
import { Card, Form, Button, ListGroup, Row, Col, Container } from 'react-bootstrap';

const BranchList = () => {
  // Sample branch data
  const sampleBranches = [
    { _id: '1', name: 'Central Branch', location: 'Downtown', contact: '123-456-789', email: 'central@example.com', manager: 'John Doe' },
    { _id: '2', name: 'West Branch', location: 'Westside', contact: '987-654-321', email: 'west@example.com', manager: 'Jane Smith' },
    { _id: '3', name: 'East Branch', location: 'Eastside', contact: '456-789-123', email: 'east@example.com', manager: 'Emma Wilson' }
  ];

  // Sample managers data
  const sampleManagers = [
    { _id: '1', name: 'John Doe' },
    { _id: '2', name: 'Jane Smith' },
    { _id: '3', name: 'Emma Wilson' }
  ];

  const [branches, setBranches] = useState(sampleBranches); // Use sample branch data
  const [selectedBranchId, setSelectedBranchId] = useState(null); // Track selected branch
  const [branchData, setBranchData] = useState({
    name: '',
    location: '',
    contact: '',
    email: '',
    manager: ''
  });

  const [managers, setManagers] = useState(sampleManagers); // Use sample managers data
  const [isNewBranch, setIsNewBranch] = useState(false); // Track if adding a new branch

  // Handle branch selection and populate branch details
  const fetchBranchDetails = (branchId) => {
    const selectedBranch = branches.find((branch) => branch._id === branchId);
    if (selectedBranch) {
      setBranchData(selectedBranch);
      setSelectedBranchId(branchId);
      setIsNewBranch(false); // Switch to edit mode
    }
  };

  // Handle input changes for the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranchData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission for updating or adding a new branch
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNewBranch) {
      // Add new branch
      setBranches([...branches, { ...branchData, _id: (branches.length + 1).toString() }]);
      alert(`New branch added: ${branchData.name}`);
    } else {
      // Update existing branch
      setBranches(
        branches.map((branch) =>
          branch._id === selectedBranchId ? { ...branchData, _id: selectedBranchId } : branch
        )
      );
      alert(`Branch details updated for: ${branchData.name}`);
    }
    setBranchData({ name: '', location: '', contact: '', email: '', manager: '' });
  };

  return (
    <Container className="mt-5">
      <Row>
        {/* Branch List */}
        <Col md={4}>
          <Card className="shadow-sm border-0">
            <Card.Header className="bg-primary text-white text-center font-weight-bold">
              Branch List
            </Card.Header>
            <ListGroup variant="flush" className="overflow-auto" style={{ maxHeight: '400px' }}>
              {branches.map((branch) => (
                <ListGroup.Item
                  key={branch._id}
                  action
                  onClick={() => fetchBranchDetails(branch._id)}
                  className={`cursor-pointer ${selectedBranchId === branch._id ? 'active' : ''}`}
                  style={{ borderBottom: '1px solid #f1f1f1' }}
                >
                  {branch.name}
                </ListGroup.Item>
              ))}
              <ListGroup.Item
                action
                onClick={() => {
                  setBranchData({ name: '', location: '', contact: '', email: '', manager: '' });
                  setIsNewBranch(true); // Switch to new branch mode
                  setSelectedBranchId(null);
                }}
                className="text-success font-weight-bold"
              >
                + Add New Branch
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>

        {/* Branch Details Form */}
        <Col md={8}>
          <Card className="shadow-sm border-0">
            <Card.Body className="p-4">
              <Card.Title className="mb-4 text-center font-weight-bold" style={{ fontSize: '1.5rem' }}>
                {isNewBranch ? 'Add New Branch' : 'Branch Details'}
              </Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBranchName">
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={branchData.name}
                    onChange={handleChange}
                    placeholder="Enter branch name"
                    className="rounded-pill"
                  />
                </Form.Group>
                <Form.Group controlId="formBranchLocation">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={branchData.location}
                    onChange={handleChange}
                    placeholder="Enter location"
                    className="rounded-pill"
                  />
                </Form.Group>
                <Form.Group controlId="formBranchContact">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact"
                    value={branchData.contact}
                    onChange={handleChange}
                    placeholder="Enter contact number"
                    className="rounded-pill"
                  />
                </Form.Group>
                <Form.Group controlId="formBranchEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={branchData.email}
                    onChange={handleChange}
                    placeholder="Enter email"
                    className="rounded-pill"
                  />
                </Form.Group>
                <Form.Group controlId="formBranchManager">
                  <Form.Label>Manager Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="manager"
                    value={branchData.manager}
                    onChange={handleChange}
                    className="rounded-pill"
                  >
                    <option value="">Select manager</option>
                    {managers.map((manager) => (
                      <option key={manager._id} value={manager.name}>
                        {manager.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="text-center">
                  <Button variant="success" type="submit" className="rounded-pill px-4">
                    {isNewBranch ? 'Add Branch' : 'Save Changes'}
                  </Button>
                  <Button variant="secondary" className="rounded-pill px-4 ml-3" type="button">
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BranchList;



// import React, { useState, useEffect } from 'react';
// import { Card, Form, Button, ListGroup, Row, Col, Container } from 'react-bootstrap';
// import axios from 'axios';

// const BranchList = () => {
//   const [branches, setBranches] = useState([]);
//   const [selectedBranchId, setSelectedBranchId] = useState(null);
//   const [branchData, setBranchData] = useState({
//     name: '',
//     location: '',
//     contact: '',
//     manager: ''
//   });
//   const [managers, setManagers] = useState([]);

//   // Fetch all branches and managers when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const branchesResponse = await axios.get('/api/branches'); // Replace with your endpoint
//         setBranches(branchesResponse.data);

//         const managersResponse = await axios.get('/api/managers'); // Replace with your endpoint
//         if (Array.isArray(managersResponse.data)) {
//           setManagers(managersResponse.data);
//         } else {
//           console.error('Unexpected data format for managers:', managersResponse.data);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   // Fetch selected branch details when a branch is clicked
//   const fetchBranchDetails = async (branchId) => {
//     try {
//       const branchResponse = await axios.get(`/api/branch/${branchId}`);
//       setBranchData(branchResponse.data);
//       setSelectedBranchId(branchId); // Set the selected branch ID
//     } catch (error) {
//       console.error('Error fetching branch details:', error);
//     }
//   };

//   // Handle input changes for the form
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setBranchData((prevData) => ({
//       ...prevData,
//       [name]: value
//     }));
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`/api/branch/${selectedBranchId}`, branchData); // Replace with the correct endpoint
//       alert('Branch details updated successfully');
//     } catch (error) {
//       console.error('Error updating branch data:', error);
//     }
//   };

//   return (
//     <Container className="mt-5">
//       <Row>
//         {/* Branch List */}
//         <Col md={4}>
//           <Card className="shadow-sm border-0">
//             <Card.Header className="bg-primary text-white text-center font-weight-bold">
//               Branch List
//             </Card.Header>
//             <ListGroup variant="flush" className="overflow-auto" style={{ maxHeight: '400px' }}>
//               {branches.map((branch) => (
//                 <ListGroup.Item
//                   key={branch._id}
//                   action
//                   onClick={() => fetchBranchDetails(branch._id)}
//                   className={`cursor-pointer ${selectedBranchId === branch._id ? 'active' : ''}`}
//                   style={{ borderBottom: '1px solid #f1f1f1' }}
//                 >
//                   {branch.name}
//                 </ListGroup.Item>
//               ))}
//             </ListGroup>
//           </Card>
//         </Col>

//         {/* Branch Details Form */}
//         <Col md={8}>
//           <Card className="shadow-sm border-0">
//             <Card.Body className="p-4">
//               <Card.Title className="mb-4 text-center font-weight-bold" style={{ fontSize: '1.5rem' }}>
//                 Branch Details
//               </Card.Title>
//               {selectedBranchId ? (
//                 <Form onSubmit={handleSubmit}>
//                   <Form.Group controlId="formBranchName">
//                     <Form.Label>Branch Name</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="name"
//                       value={branchData.name}
//                       onChange={handleChange}
//                       placeholder="Enter branch name"
//                       className="rounded-pill"
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formBranchLocation">
//                     <Form.Label>Location</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="location"
//                       value={branchData.location}
//                       onChange={handleChange}
//                       placeholder="Enter location"
//                       className="rounded-pill"
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formBranchContact">
//                     <Form.Label>Contact Details</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="contact"
//                       value={branchData.contact}
//                       onChange={handleChange}
//                       placeholder="Enter contact details"
//                       className="rounded-pill"
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formBranchManager">
//                     <Form.Label>Manager</Form.Label>
//                     <Form.Control
//                       as="select"
//                       name="manager"
//                       value={branchData.manager}
//                       onChange={handleChange}
//                       className="rounded-pill"
//                     >
//                       <option value="">Select manager</option>
//                       {managers.map((manager) => (
//                         <option key={manager._id} value={manager._id}>
//                           {manager.name}
//                         </option>
//                       ))}
//                     </Form.Control>
//                   </Form.Group>
//                   <div className="text-center">
//                     <Button variant="success" type="submit" className="rounded-pill px-4">
//                       Save Changes
//                     </Button>
//                     <Button variant="secondary" className="rounded-pill px-4 ml-3" type="button">
//                       Cancel
//                     </Button>
//                   </div>
//                 </Form>
//               ) : (
//                 <p className="text-center text-muted">Select a branch to view and edit its details.</p>
//               )}
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default BranchList;


import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Alert, Spinner, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddClass = () => {
  const [className, setClassName] = useState('');
  const [tuitionFees, setTuitionFees] = useState('');
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [teachersLoading, setTeachersLoading] = useState(true);
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await axios.get('http://94.130.56.59:5001/api/teachers');
        setTeachers(response.data);
        setTeachersLoading(false);
      } catch (error) {
        console.error('Error fetching teachers:', error.response?.data || error.message);
        setAlertMessage('Failed to load teachers. Please try again.');
        setAlertType('danger');
        setShowAlert(true);
        setTeachersLoading(false);
      }
    };

    fetchTeachers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!className || !tuitionFees || !selectedTeacher) {
      setAlertMessage('All fields are required');
      setAlertType('danger');
      setShowAlert(true);
      return;
    }

    // Prepare the data
    const classData = {
      name: className,
      tuitionFees: parseFloat(tuitionFees),
      teacher: selectedTeacher,
    };

    setLoading(true);

    try {
      // Make the POST request to the backend
      await axios.post('http://94.130.56.59:5001/api/classes', classData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setAlertMessage('Class added successfully!');
      setAlertType('success');
      setShowAlert(true);
      navigate('/classes/view'); // Redirect to view classes page

      // Clear form fields
      setClassName('');
      setTuitionFees('');
      setSelectedTeacher('');
    } catch (error) {
      console.error('Error adding class:', error.response?.data || error.message);
      setAlertMessage('Failed to add class. Please try again.');
      setAlertType('danger');
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <Card>
        <Card.Header as="h4" className="text-center">Add New Class</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formClassName" className="mb-3">
              <Form.Label>Class Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter class name"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formTuitionFees" className="mb-3">
              <Form.Label>Monthly Tuition Fees</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter tuition fees"
                value={tuitionFees}
                onChange={(e) => setTuitionFees(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formTeacher" className="mb-3">
              <Form.Label>Select Class Teacher</Form.Label>
              <Form.Select
                value={selectedTeacher}
                onChange={(e) => setSelectedTeacher(e.target.value)}
                required
                disabled={teachersLoading}
              >
                <option value="">Select a teacher</option>
                {teachers.map(teacher => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Add Class'}
            </Button>
          </Form>

          {showAlert && (
            <Alert variant={alertType} className="mt-3" onClose={() => setShowAlert(false)} dismissible>
              {alertMessage}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddClass;

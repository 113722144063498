import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const StudentDetails = () => {
  const [studentData, setStudentData] = useState(null);
  const { studentId } = useParams();


  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`https://94.130.56.59:5001/api/students/${studentId}`);
        setStudentData(response.data);
      } catch (error) {
        console.error('Failed to fetch student data:', error);
      }
    };

    fetchStudentData();
  }, [studentId]);

  if (!studentData) {
    return <div>Loading...</div>; // Show a loading message or spinner while data is being fetched
  }

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <Card.Title className="text-center mb-4">Admission Form Details</Card.Title>
          <Row>
            <Col md={4}>
              <img    src={studentData.picture ? `${window.location.origin}/uploads/${studentData.picture}` : '/default-avatar.png'} className="img-fluid rounded-circle" />
              <h5 className="mt-2">{studentData.studentName}</h5>
            </Col>
            <Col md={8}>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Registration No</td>
                    <td>{studentData.registrationNo}</td>
                  </tr>
                  <tr>
                    <td>Date of Admission</td>
                    <td>{studentData.dateOfAdmission}</td>
                  </tr>
                  <tr>
                    <td>Select Class</td>
                    <td>{studentData.selectClass}</td>
                  </tr>
                  <tr>
                    <td>Discount In Fee</td>
                    <td>{studentData.discountInFee}</td>
                  </tr>
                  <tr>
                    <td>Mobile No. for SMS/WhatsApp</td>
                    <td>{studentData.mobileNo}</td>
                  </tr>
                  <tr>
                    <td>Date Of Birth</td>
                    <td>{studentData.dateOfBirth}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{studentData.gender}</td>
                  </tr>
                  <tr>
                    <td>Any Identification Mark?</td>
                    <td>{studentData.identificationMark}</td>
                  </tr>
                  <tr>
                    <td>Blood Group</td>
                    <td>{studentData.bloodGroup}</td>
                  </tr>
                  <tr>
                    <td>Disease If Any?</td>
                    <td>{studentData.diseaseIfAny}</td>
                  </tr>
                  <tr>
                    <td>Student Birth Form ID / NIC</td>
                    <td>{studentData.studentBirthFormId}</td>
                  </tr>
                  <tr>
                    <td>Cast</td>
                    <td>{studentData.cast}</td>
                  </tr>
                  <tr>
                    <td>Previous School</td>
                    <td>{studentData.previousSchool}</td>
                  </tr>
                  <tr>
                    <td>Previous ID / Board Roll No</td>
                    <td>{studentData.previousID}</td>
                  </tr>
                  <tr>
                    <td>Any Additional Note</td>
                    <td>{studentData.additionalNote}</td>
                  </tr>
                  <tr>
                    <td>Orphan Student</td>
                    <td>{studentData.orphanStudent}</td>
                  </tr>
                  <tr>
                    <td>OSC</td>
                    <td>{studentData.osc}</td>
                  </tr>
                  <tr>
                    <td>Religion</td>
                    <td>{studentData.religion}</td>
                  </tr>
                  <tr>
                    <td>Family Background</td>
                    <td>{studentData.familyBackground}</td>
                  </tr>
                  <tr>
                    <td>Number of Siblings</td>
                    <td>{studentData.numberOfSiblings}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>{studentData.address}</td>
                  </tr>
                </tbody>
              </Table>
              <Card.Title className="mt-4">Father's Information</Card.Title>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Father’s Name</td>
                    <td>{studentData.fatherName}</td>
                  </tr>
                  <tr>
                    <td>Father’s Education</td>
                    <td>{studentData.fatherEducation}</td>
                  </tr>
                  <tr>
                    <td>Father’s ID</td>
                    <td>{studentData.fatherId}</td>
                  </tr>
                  <tr>
                    <td>Father’s Mobile No.</td>
                    <td>{studentData.fatherMobileNo}</td>
                  </tr>
                  <tr>
                    <td>Father’s Occupation</td>
                    <td>{studentData.fatherOccupation}</td>
                  </tr>
                  <tr>
                    <td>Father’s Profession</td>
                    <td>{studentData.fatherProfession}</td>
                  </tr>
                  <tr>
                    <td>Father’s Income</td>
                    <td>{studentData.fatherIncome}</td>
                  </tr>
                </tbody>
              </Table>
              <Card.Title className="mt-4">Mother's Information</Card.Title>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Mother’s Name</td>
                    <td>{studentData.motherName}</td>
                  </tr>
                  <tr>
                    <td>Mother’s Education</td>
                    <td>{studentData.motherEducation}</td>
                  </tr>
                  <tr>
                    <td>Mother’s ID</td>
                    <td>{studentData.motherId}</td>
                  </tr>
                  <tr>
                    <td>Mother’s Mobile No.</td>
                    <td>{studentData.motherMobileNo}</td>
                  </tr>
                  <tr>
                    <td>Mother’s Occupation</td>
                    <td>{studentData.motherOccupation}</td>
                  </tr>
                  <tr>
                    <td>Mother’s Profession</td>
                    <td>{studentData.motherProfession}</td>
                  </tr>
                  <tr>
                    <td>Mother’s Income</td>
                    <td>{studentData.motherIncome}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StudentDetails;


// import React from 'react';
// import { Container, Row, Col, Table, Card, Button, Form } from 'react-bootstrap';

// // Sample data
// const sampleData = {
//   studentName: 'John Doe',
//   picture: 'https://via.placeholder.com/150',
//   registrationNo: '12345',
//   dateOfAdmission: '09/08/2024',
//   selectClass: 'Class A',
//   discountInFee: '10%',
//   mobileNo: '+1234567890',
//   dateOfBirth: '01/01/2005',
//   gender: 'Male',
//   identificationMark: 'None',
//   bloodGroup: 'O+',
//   diseaseIfAny: 'None',
//   studentBirthFormId: 'AB123456',
//   cast: 'General',
//   previousSchool: 'XYZ School',
//   previousID: '456789',
//   additionalNote: 'N/A',
//   orphanStudent: 'No',
//   osc: 'No',
//   religion: 'Christianity',
//   familyBackground: 'N/A',
//   numberOfSiblings: '2',
//   address: '123 Main St, City, Country',
//   fatherName: 'Michael Doe',
//   fatherEducation: 'Bachelors',
//   fatherId: 'ID123456',
//   fatherMobileNo: '+0987654321',
//   fatherOccupation: 'Engineer',
//   fatherProfession: 'Mechanical',
//   fatherIncome: '$5000/month',
//   motherName: 'Sarah Doe',
//   motherEducation: 'Masters',
//   motherId: 'ID654321',
//   motherMobileNo: '+1122334455',
//   motherOccupation: 'Teacher',
//   motherProfession: 'Education',
//   motherIncome: '$3000/month',
// };

// const StudentDetails = () => {
//   return (
//     <Container>
//       <Card className="my-4">
//         <Card.Body>
//           <Card.Title className="text-center mb-4">Admission Form Details</Card.Title>
//           <Row>
//             <Col md={4}>
//               <img src={sampleData.picture} alt="Student" className="img-fluid rounded-circle" />
//               <h5 className="mt-2">{sampleData.studentName}</h5>
//             </Col>
//             <Col md={8}>
//               <Table striped bordered hover>
//                 <tbody>
//                   <tr>
//                     <td>Registration No</td>
//                     <td>{sampleData.registrationNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Date of Admission</td>
//                     <td>{sampleData.dateOfAdmission}</td>
//                   </tr>
//                   <tr>
//                     <td>Select Class</td>
//                     <td>{sampleData.selectClass}</td>
//                   </tr>
//                   <tr>
//                     <td>Discount In Fee</td>
//                     <td>{sampleData.discountInFee}</td>
//                   </tr>
//                   <tr>
//                     <td>Mobile No. for SMS/WhatsApp</td>
//                     <td>{sampleData.mobileNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Date Of Birth</td>
//                     <td>{sampleData.dateOfBirth}</td>
//                   </tr>
//                   <tr>
//                     <td>Gender</td>
//                     <td>{sampleData.gender}</td>
//                   </tr>
//                   <tr>
//                     <td>Any Identification Mark?</td>
//                     <td>{sampleData.identificationMark}</td>
//                   </tr>
//                   <tr>
//                     <td>Blood Group</td>
//                     <td>{sampleData.bloodGroup}</td>
//                   </tr>
//                   <tr>
//                     <td>Disease If Any?</td>
//                     <td>{sampleData.diseaseIfAny}</td>
//                   </tr>
//                   <tr>
//                     <td>Student Birth Form ID / NIC</td>
//                     <td>{sampleData.studentBirthFormId}</td>
//                   </tr>
//                   <tr>
//                     <td>Cast</td>
//                     <td>{sampleData.cast}</td>
//                   </tr>
//                   <tr>
//                     <td>Previous School</td>
//                     <td>{sampleData.previousSchool}</td>
//                   </tr>
//                   <tr>
//                     <td>Previous ID / Board Roll No</td>
//                     <td>{sampleData.previousID}</td>
//                   </tr>
//                   <tr>
//                     <td>Any Additional Note</td>
//                     <td>{sampleData.additionalNote}</td>
//                   </tr>
//                   <tr>
//                     <td>Orphan Student</td>
//                     <td>{sampleData.orphanStudent}</td>
//                   </tr>
//                   <tr>
//                     <td>OSC</td>
//                     <td>{sampleData.osc}</td>
//                   </tr>
//                   <tr>
//                     <td>Religion</td>
//                     <td>{sampleData.religion}</td>
//                   </tr>
//                   <tr>
//                     <td>Family Background</td>
//                     <td>{sampleData.familyBackground}</td>
//                   </tr>
//                   <tr>
//                     <td>Number of Siblings</td>
//                     <td>{sampleData.numberOfSiblings}</td>
//                   </tr>
//                   <tr>
//                     <td>Address</td>
//                     <td>{sampleData.address}</td>
//                   </tr>
//                 </tbody>
//               </Table>
//               <Card.Title className="mt-4">Father's Information</Card.Title>
//               <Table striped bordered hover>
//                 <tbody>
//                   <tr>
//                     <td>Father’s Name</td>
//                     <td>{sampleData.fatherName}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Education</td>
//                     <td>{sampleData.fatherEducation}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s ID</td>
//                     <td>{sampleData.fatherId}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Mobile No.</td>
//                     <td>{sampleData.fatherMobileNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Occupation</td>
//                     <td>{sampleData.fatherOccupation}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Profession</td>
//                     <td>{sampleData.fatherProfession}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Income</td>
//                     <td>{sampleData.fatherIncome}</td>
//                   </tr>
//                 </tbody>
//               </Table>
//               <Card.Title className="mt-4">Mother's Information</Card.Title>
//               <Table striped bordered hover>
//                 <tbody>
//                   <tr>
//                     <td>Mother’s Name</td>
//                     <td>{sampleData.motherName}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Education</td>
//                     <td>{sampleData.motherEducation}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s ID</td>
//                     <td>{sampleData.motherId}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Mobile No.</td>
//                     <td>{sampleData.motherMobileNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Occupation</td>
//                     <td>{sampleData.motherOccupation}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Profession</td>
//                     <td>{sampleData.motherProfession}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Income</td>
//                     <td>{sampleData.motherIncome}</td>
//                   </tr>
//                 </tbody>
//               </Table>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default StudentDetails;

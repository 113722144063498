import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Card, Alert } from 'react-bootstrap';
import axios from 'axios';

const JobPostingReports = () => {
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await axios.get('https://94.130.56.59:5001/reports');
        setReportData(response.data);
      } catch (error) {
        setError('Failed to fetch report data');
      }
    };

    fetchReportData();
  }, []);

  return (
    <Container fluid className="mt-4" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center text-primary" style={{ fontWeight: 700 }}>Job Posting Reports</h2>
        </Col>
      </Row>

      {/* Job Posting Summary */}
      <Row className="mb-4">
        <Col>
          <Card className="bg-light shadow-sm">
            <Card.Body>
              <h4 className="text-info" style={{ fontWeight: 500 }}>Job Posting Summary</h4>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th style={{ color: '#007bff', fontWeight: 500 }}>Total Job Postings</th>
                    <th style={{ color: '#28a745', fontWeight: 500 }}>Active</th>
                    <th style={{ color: '#dc3545', fontWeight: 500 }}>Closed</th>
                    <th style={{ color: '#ffc107', fontWeight: 500 }}>Pending</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{reportData ? reportData.jobPostings.total : 'N/A'}</td>
                    <td>{reportData ? reportData.jobPostings.active : 'N/A'}</td>
                    <td>{reportData ? reportData.jobPostings.closed : 'N/A'}</td>
                    <td>{reportData ? reportData.jobPostings.pending : 'N/A'}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Application Stats */}
      <Row className="mb-4">
        <Col>
          <Card className="bg-light shadow-sm">
            <Card.Body>
              <h4 className="text-success" style={{ fontWeight: 500 }}>Application Statistics</h4>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th style={{ color: '#17a2b8', fontWeight: 500 }}>Job Title</th>
                    <th style={{ color: '#28a745', fontWeight: 500 }}>Total Applications</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData ? reportData.applicationStats.byJob.map((job, index) => (
                    <tr key={index}>
                      <td>{job.jobTitle}</td>
                      <td>{job.applications}</td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="2">N/A</td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <h5 className="text-warning mt-4" style={{ fontWeight: 500 }}>Application Status Breakdown</h5>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th style={{ color: '#007bff', fontWeight: 500 }}>Received</th>
                    <th style={{ color: '#ffc107', fontWeight: 500 }}>Under Review</th>
                    <th style={{ color: '#28a745', fontWeight: 500 }}>Interview Scheduled</th>
                    <th style={{ color: '#dc3545', fontWeight: 500 }}>Hired</th>
                    <th style={{ color: '#6c757d', fontWeight: 500 }}>Rejected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{reportData ? reportData.applicationStats.statusBreakdown.received : 'N/A'}</td>
                    <td>{reportData ? reportData.applicationStats.statusBreakdown.underReview : 'N/A'}</td>
                    <td>{reportData ? reportData.applicationStats.statusBreakdown.interviewScheduled : 'N/A'}</td>
                    <td>{reportData ? reportData.applicationStats.statusBreakdown.hired : 'N/A'}</td>
                    <td>{reportData ? reportData.applicationStats.statusBreakdown.rejected : 'N/A'}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Hiring Outcomes */}
      <Row className="mb-4">
        <Col>
          <Card className="bg-light shadow-sm">
            <Card.Body>
              <h4 className="text-danger" style={{ fontWeight: 500 }}>Hiring Outcomes</h4>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th style={{ color: '#007bff', fontWeight: 500 }}>Job Title</th>
                    <th style={{ color: '#28a745', fontWeight: 500 }}>Hired</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData ? reportData.hiringOutcomes.map((outcome, index) => (
                    <tr key={index}>
                      <td>{outcome.jobTitle}</td>
                      <td>{outcome.hired}</td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="2">N/A</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Error Message */}
      {error && (
        <Row className="mb-4">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default JobPostingReports;

import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, Badge, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';

const ActivityLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch activity logs from backend on component mount
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get('https://94.130.56.59:5001/api/activity-logs');
        setLogs(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to load activity logs');
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  return (
    <Container fluid className="mt-4">
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header as="h5" className="bg-primary text-white">
              <i className="bi bi-file-text"></i> Admin Activity Logs
            </Card.Header>
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <Table striped bordered hover responsive className="text-center">
                  <thead className="table-primary">
                    <tr>
                      <th>User</th>
                      <th>Action</th>
                      <th>Timestamp</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.length > 0 ? (
                      logs.map((log) => (
                        <tr key={log._id}>
                          <td>{log.user}</td>
                          <td>
                            <Badge bg="info">{log.action}</Badge>
                          </td>
                          <td>{new Date(log.timestamp).toLocaleString()}</td>
                          <td>{log.description}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No activity logs found.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ActivityLog;

// src/components/ViewClasses.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Form, Spinner, Alert, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

// Sample demo data removed, actual data will be fetched from the API

const ViewClasses = () => {
  const [classes, setClasses] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    // Fetch classes data from the API
    const fetchClasses = async () => {
      try {
        const response = await axios.get('https://94.130.56.59:5001/api/classes');
        setClasses(response.data.classes);
      } catch (error) {
        console.error('Error fetching classes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  const handleDelete = async () => {
    try {
      await axios.delete(`https://94.130.56.59:5001/api/classes/${deleteId}`);
      setClasses(classes.filter(cls => cls._id !== deleteId));
      setAlert({ show: true, message: 'Class deleted successfully!', type: 'success' });
    } catch (error) {
      setAlert({ show: true, message: 'Failed to delete class. Please try again.', type: 'danger' });
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredClasses = classes.filter(cls =>
    cls.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Container className="my-5">
      <h1 className="mb-4 text-center">Class Directory</h1>

      <Form.Group className="mb-4">
        <Form.Control
          type="text"
          placeholder="Search Class"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="rounded-pill"
        />
      </Form.Group>

      <Button
        variant="primary"
        as={Link}
        to="/classes/add"
        className="mb-4 d-block mx-auto"
        style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
      >
        Add New Class
      </Button>

      {loading ? (
        <div className="d-flex justify-content-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Row>
          {filteredClasses.length > 0 ? (
            filteredClasses.map(cls => (
              <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex" key={cls._id}>
                <Card className="w-100 h-100 d-flex flex-column">
                  <Card.Body className="d-flex flex-column">
                    <Card.Title className="text-center">{cls.name}</Card.Title>
                    <Card.Text className="text-center flex-grow-1">
                      <strong>Monthly Tuition Fees:</strong> ${cls.tuitionFees.toFixed(2)}<br />
                      <strong>Class Teacher:</strong> {cls.teacher}
                    </Card.Text>
                    <div className="d-flex justify-content-around mt-3">
                      <Button
                        variant="warning"
                        as={Link}
                        to={`/classes/edit/${cls._id}`}
                        style={{ width: '45%' }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => { setShowDeleteModal(true); setDeleteId(cls._id); }}
                        style={{ width: '45%' }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col className="text-center">
              <p>No classes found</p>
            </Col>
          )}
        </Row>
      )}

      <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
        {alert.message}
      </Alert>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this class?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ViewClasses;

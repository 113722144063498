import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';

const AddBranch = () => {
  const [branch, setBranch] = useState({
    name: '',
    location: '',
    contactNumber: '',
    email: '',
    managerName: '',
  });

  const [managers, setManagers] = useState([]); // Ensure this is initialized as an array
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await axios.get('https://94.130.56.59:5001/api/managers'); // Replace with your endpoint
        if (Array.isArray(response.data)) {
          setManagers(response.data); // Ensure response.data is an array
        } else {
          console.error('Unexpected data format:', response.data);
          setManagers([]); // Handle unexpected format by resetting to an empty array
        }
      } catch (error) {
        console.error('Error fetching managers:', error);
        setManagers([]); // Handle error by resetting to an empty array
      }
    };

    fetchManagers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranch({
      ...branch,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/branches', branch); // Replace with your endpoint
      setShowAlert(true);
      setBranch({
        name: '',
        location: '',
        contactNumber: '',
        email: '',
        managerName: '',
      });
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } catch (error) {
      console.error('Error creating branch:', error);
    }
  };

  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">Add New Branch</Card.Title>
              {showAlert && (
                <Alert variant="success" className="text-center">
                  Branch Added Successfully!
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBranchName" className="mb-3">
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter branch name"
                    name="name"
                    value={branch.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBranchLocation" className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter location"
                    name="location"
                    value={branch.location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBranchContactNumber" className="mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact number"
                    name="contactNumber"
                    value={branch.contactNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBranchEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={branch.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBranchManagerName" className="mb-3">
                  <Form.Label>Manager Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="managerName"
                    value={branch.managerName}
                    onChange={handleChange}
                  >
                    <option value="">Select manager</option>
                    {managers.map(manager => (
                      <option key={manager.id} value={manager.name}>
                        {manager.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Add Branch
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddBranch;

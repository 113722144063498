import React from 'react';
import { Navbar, Nav, Form, FormControl, Button, Dropdown, Badge, Container } from 'react-bootstrap';
import { FaSearch, FaBell, FaUserCircle } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included

const TopBar = () => {
  return (
    <Navbar bg="primary" variant="dark" expand="lg" className="shadow-sm">
      <Container fluid>
        <Navbar.Brand href="#home" className="fw-bold">
          <span className="text-light">Admin Panel</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Form className="d-flex mx-auto" style={{ maxWidth: '600px' }}>
            <FormControl
              type="search"
              placeholder="Search..."
              className="me-2"
              aria-label="Search"
              style={{ borderRadius: '30px', padding: '0.5rem 1rem' }}
            />
            <Button variant="outline-light" style={{ borderRadius: '30px' }}>
              <FaSearch size={20} />
            </Button>
          </Form>
          <Nav className="ms-auto">
            <Nav.Item className="d-flex align-items-center me-3">
              <Button variant="link" className="text-light position-relative">
                <FaBell size={22} />
                <Badge bg="danger" pill className="position-absolute top-0 start-100 translate-middle">
                  5
                </Badge>
              </Button>
            </Nav.Item>
            <Nav.Item>
              <Dropdown align="end">
                <Dropdown.Toggle variant="link" className="text-light">
                  <FaUserCircle size={22} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#profile">Profile</Dropdown.Item>
                  <Dropdown.Item href="#settings">Settings</Dropdown.Item>
                  <Dropdown.Item href="#logout">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopBar;

import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Alert, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';

const CreateSubject = () => {
  const [subject, setSubject] = useState({
    className: '',
    subjectName: '',
    marks: ''
  });
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  useEffect(() => {
    // Fetch classes from the backend
    const fetchClasses = async () => {
      try {
        const response = await axios.get('http://94.130.56.59:5001/api/classes');
        setClasses(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching classes:', err);
        setError('Failed to fetch classes. Please try again later.');
        setLoading(false);
      }
    };

    fetchClasses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubject({ ...subject, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!subject.className) {
      setError('Please select a class first.');
      return;
    }
    
    try {
      await axios.post('http://94.130.56.59:5001/api/subjects', subject);
      setSuccess('Subject created successfully!');
      setError(null);
      setSubject({
        className: '',
        subjectName: '',
        marks: ''
      });
    } catch (err) {
      console.error('Error creating subject:', err);
      setError('Failed to create subject. Please try again.');
      setSuccess(null);
    }
  };

  return (
    <Container className="my-4">
      <Card>
        <Card.Header as="h5" className="text-center">Create Subject</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formClassName" className="mb-3">
              <Form.Label>Select Class</Form.Label>
              <Form.Control
                as="select"
                name="className"
                value={subject.className}
                onChange={handleChange}
                required
              >
                <option value="">Select a class</option>
                {classes.map(cls => (
                  <option key={cls._id} value={cls.name}>{cls.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formSubjectName" className="mb-3">
              <Form.Label>Subject Name</Form.Label>
              <Form.Control
                type="text"
                name="subjectName"
                value={subject.subjectName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formMarks" className="mb-3">
              <Form.Label>Marks</Form.Label>
              <Form.Control
                type="number"
                name="marks"
                value={subject.marks}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Save Subject'}
            </Button>
          </Form>

          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
          {success && <Alert variant="success" className="mt-3">{success}</Alert>}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateSubject;

import React, { useState } from 'react';
import { Container, Table, Form, Button, Row, Col, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BlogManagement = () => {
  const [blogs, setBlogs] = useState([
    {
      id: 1,
      title: 'First Blog',
      author: 'John Doe',
      category: 'Technology',
      content: 'Content of the first blog...',
    },
    {
      id: 2,
      title: 'Second Blog',
      author: 'Jane Smith',
      category: 'Lifestyle',
      content: 'Content of the second blog...',
    },
  ]);

  const [editingBlog, setEditingBlog] = useState(null);

  const handleDelete = (id) => {
    setBlogs(blogs.filter((blog) => blog.id !== id));
  };

  const handleEdit = (blog) => {
    setEditingBlog(blog);
  };

  const handleEditSubmit = (updatedBlog) => {
    setBlogs(blogs.map((blog) => (blog.id === updatedBlog.id ? updatedBlog : blog)));
    setEditingBlog(null);
  };

  return (
    <Container fluid className="mt-4" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <Card className="p-4 shadow-sm">
        <h2 className="text-center text-primary mb-4">Blog Management</h2>
        <Table striped bordered hover>
          <thead className="text-info">
            <tr>
              <th>Title</th>
              <th>Author</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog) => (
              <tr key={blog.id}>
                <td>{blog.title}</td>
                <td>{blog.author}</td>
                <td>{blog.category}</td>
                <td>
                  <Button variant="outline-primary" onClick={() => handleEdit(blog)} className="me-2">
                    Edit
                  </Button>
                  <Button variant="outline-danger" onClick={() => handleDelete(blog.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {editingBlog && (
          <EditBlogPost blogData={editingBlog} onSubmit={handleEditSubmit} />
        )}
      </Card>
    </Container>
  );
};

const EditBlogPost = ({ blogData, onSubmit }) => {
  const [blog, setBlog] = useState(blogData);

  const handleChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  const handleContentChange = (content) => {
    setBlog({ ...blog, content });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(blog);
  };

  return (
    <Card className="p-4 mt-4 shadow-sm">
      <h4 className="text-center text-info">Edit Blog Post</h4>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formEditBlogTitle">
              <Form.Label className="text-info">Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={blog.title}
                onChange={handleChange}
                placeholder="Enter blog title"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEditBlogAuthor">
              <Form.Label className="text-info">Author</Form.Label>
              <Form.Control
                type="text"
                name="author"
                value={blog.author}
                onChange={handleChange}
                placeholder="Enter author name"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formEditBlogCategory">
              <Form.Label className="text-info">Category</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={blog.category}
                onChange={handleChange}
                placeholder="Enter blog category"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group controlId="formEditBlogContent">
              <Form.Label className="text-info">Content</Form.Label>
              <ReactQuill
                value={blog.content}
                onChange={handleContentChange}
                theme="snow"
                placeholder="Edit your blog content here..."
                style={{ height: '200px' }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button type="submit" variant="primary" className="w-100">
              Update Blog Post
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BlogManagement;

import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { Container, Row, Col, Table, Button, InputGroup, Form, Tooltip, OverlayTrigger, Modal, Alert } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import ViewDetailsModal from './ViewDetailsModal';
import AddVisitorModal from './AddVisitorModal'; // Assuming you moved the modal to its own file
import { FaEye, FaSearch, FaTimes, FaPlus, FaEdit, FaTrash, FaSync } from 'react-icons/fa';
import axios from 'axios';



// API Functions
const fetchVisitors = async () => {
  try {
    const response = await axios.get('https://94.130.56.59:5001/visitors');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addVisitor = async (visitor) => {
  try {
    const response = await axios.post('http://localhost:5001/visitors', visitor);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateVisitor = async (visitor) => {
  try {
    const response = await axios.put(`http://localhost:5001/visitors/${visitor._id}`, visitor);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteVisitor = async (_id) => {
  await axios.delete(`http://localhost:5001/visitors/${_id}`);
};

// Global Filter Component
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <InputGroup className="mb-3">
    <Form.Control
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder="Search..."
      aria-label="Search"
      aria-describedby="search-icon"
    />
    <InputGroup.Text id="search-icon">
      <FaSearch />
      {globalFilter && (
        <OverlayTrigger
          placement="end"
          overlay={<Tooltip id="clear-tooltip">Clear search</Tooltip>}
        >
          <Button variant="link" onClick={() => setGlobalFilter('')}>
            <FaTimes />
          </Button>
        </OverlayTrigger>
      )}
    </InputGroup.Text>
  </InputGroup>
);

// Visitor Data Table Component
const VisitorDataTable = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState([]);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentVisitor, setCurrentVisitor] = useState(null);
  const [error, setError] = useState(null);

  // Fetch initial data
  const loadData = useCallback(async () => {
    try {
      const visitors = await fetchVisitors();
      setData(visitors);
      setError(null);
      // Clear any previous error
    } catch (error) {
      setError('Failed to fetch visitors. Please try again later.');
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleViewDetails = useCallback((visitor) => {
    setSelectedVisitor(visitor);
    setShowDetails(true);
  }, []);

  const handleEditVisitor = useCallback((visitor) => {
    setCurrentVisitor(visitor);
    setShowEditModal(true);
  }, []);

  const handleDeleteVisitor = useCallback(async (_id) => {
    try {
      // Make API call to delete visitor
      await deleteVisitor(_id);

      // Update local state
      setData(prevData => prevData.filter(visitor => visitor._id !== _id));
      setError(null); // Clear any previous error
    } catch (error) {
      setError('Failed to delete visitor. Please try again later.');
    }
  }, []);

  const handleAddVisitor = useCallback(async (visitor) => {
    try {
      const newVisitor = await addVisitor(visitor);
      setData(prevData => [...prevData, newVisitor]);
      setError(null); // Clear any previous error
    } catch (error) {
      setError('Failed to add visitor. Please try again later.');
    }
  }, []);

  const handleEditVisitorSubmit = useCallback(async (visitor) => {
    try {
      const updatedVisitor = await updateVisitor(visitor);
      setData(prevData => prevData.map(v => (v._id === updatedVisitor._id ? updatedVisitor : v)));
      setError(null); // Clear any previous error
    } catch (error) {
      // setError('Failed to update visitor. Please try again later.');
    }
  }, [updateVisitor]);

  // Memoizing columns to avoid re-renders
  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Purpose of Visit', accessor: 'purposeOfVisit' },
      { Header: 'Time In', accessor: 'timeIn' },
      { Header: 'Time Out', accessor: 'timeOut' },
      { Header: 'Source', accessor: 'source' },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div>
            <Button variant="info" onClick={() => handleViewDetails(row.original)} className="me-2">
              <FaEye /> View
            </Button>
            <Button variant="warning" onClick={() => handleEditVisitor(row.original)} className="me-2">
              <FaEdit /> Edit
            </Button>
            <Button variant="danger" onClick={() => handleDeleteVisitor(row.original._id)}>
              <FaTrash /> Delete
            </Button>
          </div>
        ),
      },
    ],
    [handleViewDetails, handleEditVisitor, handleDeleteVisitor]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Container fluid className="mt-4">
      <Row className="mb-3">
        <Col>
          <h2 className="text-center text-primary">Visitor Log</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        </Col>
        <Col md={6} className="text-end">
          <Button variant="success" onClick={() => setShowAddModal(true)}>
            <FaPlus /> Add Visitor
          </Button>
          <Button variant="secondary" onClick={loadData} className="ms-2">
            <FaSync /> Reload
          </Button>
          <CSVLink data={data} className="btn btn-primary ms-2">
            Download CSV
          </CSVLink>
        </Col>
      </Row>

      {error && (
        <Row className="mb-3">
          <Col>
            <Alert variant="danger">
              {error}
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Table {...getTableProps()} bordered hover responsive>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      {selectedVisitor && (
        <ViewDetailsModal
          show={showDetails}
          handleClose={() => setShowDetails(false)}
          visitor={selectedVisitor}
        />
      )}

      <AddVisitorModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        handleAddVisitor={handleAddVisitor}
      />

      {currentVisitor && (
        <AddVisitorModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          handleAddVisitor={handleEditVisitorSubmit}
          visitor={currentVisitor}
        />
      )}
    </Container>
  );
};

export default VisitorDataTable;




// import React, { useState, useMemo, useCallback } from 'react';
// import { useTable, useGlobalFilter, useSortBy } from 'react-table';
// import { Container, Row, Col, Table, Button, InputGroup, Form, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
// import { CSVLink } from 'react-csv';
// import ViewDetailsModal from './ViewDetailsModal';
// import AddVisitorModal from './AddVisitorModal'; // Assuming you moved the modal to its own file
// import { FaEye, FaSearch, FaTimes, FaPlus, FaEdit, FaTrash } from 'react-icons/fa';

// // Sample Data
// const sampleData = [
//   {
//     id: 1,
//     name: 'John Doe',
//     address: '123 Main St',
//     contact: '555-1234',
//     gender: 'Male',
//     email: 'johndoe@example.com',
//     college: 'Some College',
//     timeIn: '10:00 AM',
//     timeOut: '11:00 AM',
//     comments: 'Discussed admission requirements and deadlines.',
//     visitHistory: [
//       '2024-08-01: Initial inquiry via phone.',
//       '2024-08-15: Follow-up email sent.',
//     ],
//     source: 'Facebook',
//   },
//   {
//     id: 2,
//     name: 'Jane Smith',
//     address: '456 Elm St',
//     contact: '555-5678',
//     gender: 'Female',
//     email: 'janesmith@example.com',
//     college: '',
//     timeIn: '01:00 PM',
//     timeOut: '02:30 PM',
//     comments: 'Tour of the campus facilities provided.',
//     visitHistory: [
//       '2024-09-01: Campus tour scheduled.',
//       '2024-09-02: Follow-up call for feedback.',
//     ],
//     source: 'Word of Mouth',
//   },
// ];

// // Global Filter Component
// const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
//   <InputGroup className="mb-3">
//     <Form.Control
//       value={globalFilter || ''}
//       onChange={(e) => setGlobalFilter(e.target.value)}
//       placeholder="Search..."
//       aria-label="Search"
//       aria-describedby="search-icon"
//     />
//     <InputGroup.Text id="search-icon">
//       <FaSearch />
//       {globalFilter && (
//         <OverlayTrigger
//           placement="end"
//           overlay={<Tooltip id="clear-tooltip">Clear search</Tooltip>}
//         >
//           <Button variant="link" onClick={() => setGlobalFilter('')}>
//             <FaTimes />
//           </Button>
//         </OverlayTrigger>
//       )}
//     </InputGroup.Text>
//   </InputGroup>
// );

// // Visitor Data Table Component
// const VisitorDataTable = () => {
//   const [data, setData] = useState(sampleData);
//   const [selectedVisitor, setSelectedVisitor] = useState(null);
//   const [showDetails, setShowDetails] = useState(false);
//   const [showAddModal, setShowAddModal] = useState(false);
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [currentVisitor, setCurrentVisitor] = useState(null);

//   const handleViewDetails = useCallback((visitor) => {
//     setSelectedVisitor(visitor);
//     setShowDetails(true);
//   }, []);

//   const handleEditVisitor = useCallback((visitor) => {
//     setCurrentVisitor(visitor);
//     setShowEditModal(true);
//   }, []);

//   const handleDeleteVisitor = useCallback((id) => {
//     setData(data.filter(visitor => visitor.id !== id));
//   }, [data]);

//   const handleAddVisitor = useCallback((visitor) => {
//     setData([...data, { ...visitor, id: Date.now() }]);
//   }, [data]);

//   const handleEditVisitorSubmit = useCallback((visitor) => {
//     setData(data.map(v => (v.id === visitor.id ? visitor : v)));
//   }, [data]);

//   // Memoizing columns to avoid re-renders
//   const columns = useMemo(
//     () => [
//       { Header: 'Name', accessor: 'name' },
//       { Header: 'Purpose of Visit', accessor: 'purposeOfVisit' },
//       { Header: 'Time In', accessor: 'timeIn' },
//       { Header: 'Time Out', accessor: 'timeOut' },
//       { Header: 'Source', accessor: 'source' },
//       {
//         Header: 'Actions',
//         accessor: 'actions',
//         Cell: ({ row }) => (
//           <div>
//             <Button variant="info" onClick={() => handleViewDetails(row.original)} className="me-2">
//               <FaEye /> View
//             </Button>
//             <Button variant="warning" onClick={() => handleEditVisitor(row.original)} className="me-2">
//               <FaEdit /> Edit
//             </Button>
//             <Button variant="danger" onClick={() => handleDeleteVisitor(row.original.id)}>
//               <FaTrash /> Delete
//             </Button>
//           </div>
//         ),
//       },
//     ],
//     [handleViewDetails, handleEditVisitor, handleDeleteVisitor]
//   );

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//     state: { globalFilter },
//     setGlobalFilter,
//   } = useTable(
//     { columns, data },
//     useGlobalFilter,
//     useSortBy
//   );

//   return (
//     <Container fluid className="mt-4">
//       <Row className="mb-3">
//         <Col>
//           <h2 className="text-center text-primary">Visitor Log</h2>
//         </Col>
//       </Row>
//       <Row className="mb-3">
//         <Col md={6}>
//           <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
//         </Col>
//         <Col md={6} className="text-end">
//           <Button variant="success" onClick={() => setShowAddModal(true)}>
//             <FaPlus /> Add Visitor
//           </Button>
//           <CSVLink data={data} className="btn btn-primary ms-2">
//             Download CSV
//           </CSVLink>
//         </Col>
//       </Row>

//       <Row>
//         <Col>
//           <Table {...getTableProps()} bordered hover responsive>
//             <thead>
//               {headerGroups.map(headerGroup => (
//                 <tr {...headerGroup.getHeaderGroupProps()}>
//                   {headerGroup.headers.map(column => (
//                     <th {...column.getHeaderProps(column.getSortByToggleProps())}>
//                       {column.render('Header')}
//                       <span>
//                         {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
//                       </span>
//                     </th>
//                   ))}
//                 </tr>
//               ))}
//             </thead>
//             <tbody {...getTableBodyProps()}>
//               {rows.map(row => {
//                 prepareRow(row);
//                 return (
//                   <tr {...row.getRowProps()}>
//                     {row.cells.map(cell => (
//                       <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
//                     ))}
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </Table>
//         </Col>
//       </Row>

//       {selectedVisitor && (
//         <ViewDetailsModal
//           show={showDetails}
//           handleClose={() => setShowDetails(false)}
//           visitor={selectedVisitor}
//         />
//       )}

//       <AddVisitorModal
//         show={showAddModal}
//         handleClose={() => setShowAddModal(false)}
//         handleAddVisitor={handleAddVisitor}
//       />

//       {currentVisitor && (
//         <AddVisitorModal
//           show={showEditModal}
//           handleClose={() => setShowEditModal(false)}
//           handleAddVisitor={handleEditVisitorSubmit}
//           visitor={currentVisitor}
//         />
//       )}
//     </Container>
//   );
// };

// export default VisitorDataTable;
